import React from 'react';
import { Box, VStack, SimpleGrid, Button, Text, useBreakpointValue } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import Chat from './Chat';
import HeaderMenus from './HeaderMenus';

function HomePage() {
    const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

    const NavButton = ({ title, subtitle, link }) => (
      <Button
          as={RouterLink}
          to={link}
          size={buttonSize}
          height="auto"
          width="100%"
          whiteSpace="normal"
          textAlign="center"
          flexDirection="column"
          py={4}
      >
          <Text fontSize="lg" fontWeight="bold">{title}</Text>
          <Text fontSize="sm" fontWeight="normal">{subtitle}</Text>
      </Button>
  );
  
  
    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <HeaderMenus />
            
            <VStack spacing={8} flex={1} p={4}>
            // Then in your SimpleGrid:
  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%" maxWidth="900px">
      <NavButton 
          title="E/M & ICD-10 Coding" 
          subtitle="Code your notes or try our samples"
          link="/note" 
      />
      <NavButton 
          title="Coding API" 
          subtitle="Explore our API capabilities"
          link="/api-docs" 
      />
      <NavButton 
          title="IONM Analysis" 
          subtitle="Coding and medical necessity checks"
          link="/ionmtext" 
      />
      <NavButton 
          title="Healthcare Chat" 
          subtitle="HIPAA-compliant AI assistant"
          link="/chat" 
      />
  </SimpleGrid>
  
                <Box width="100%" maxWidth="900px">
                    <Text fontSize="xl" fontWeight="bold" mb={4}>Chat Interface</Text>
                    <Chat 
                        chatOnly={true}
                        contextType={'revedy'} 
                        contextData={'revedy_welcome'} 
                        contextPersona={'revedy_welcome'}
                        useOptions={true}
                        maintainContext={true}
                    />
                </Box>
            </VStack>

            <Box as="footer" borderTop="1px" borderColor="gray.200" p={4} textAlign="center">
                <Text fontSize="sm">© 2024 Revedy, Inc. All rights reserved</Text>
            </Box>
        </Box>
    );
}

export default HomePage;