import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import {
  ChakraProvider,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

function ReviewSummary() {
  const [summaryData, setSummaryData] = useState([]);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSummaryData();
  }, [accessToken]);

  const fetchSummaryData = async () => {
    try {
      const response = await callApi('/review_summary', accessToken);
      if (response.ok) {
        setSummaryData(await response.json());
      } else {
        throw new Error('Failed to fetch review summary');
      }
    } catch (error) {
      console.error('Error fetching review summary:', error);
      toast({
        title: 'Error fetching review summary',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRowClick = (encounterId) => {
    navigate(`/review-details/${encounterId}`);
  };

  return (
    <ChakraProvider>
      <Box p={6}>
        <Heading mb={6}>Review Summary</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Encounter ID</Th>
              <Th>Transcript Score</Th>
              <Th>Note Score</Th>
              <Th>Avg Code Score</Th>
              <Th>Reviewed At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {summaryData.map((item, index) => (
              <Tr key={index} onClick={() => handleRowClick(item.encounter_id)} cursor="pointer" _hover={{ bg: "gray.100" }}>
                <Td>{item.encounter_id}</Td>
                <Td>{item.transcript_score}</Td>
                <Td>{item.note_score}</Td>
                <Td>{item.avg_code_score ? item.avg_code_score.toFixed(2) : 'N/A'}</Td>
                <Td>{new Date(item.reviewed_at).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </ChakraProvider>
  );
}

export default ReviewSummary;
