import io from "socket.io-client";
import React from 'react'
import Rating from './Rating';
import { 
    Box,
    Grid,
    GridItem,
  //  Input, 
    Textarea, 
    FormControl,
    FormLabel,
    Input, 
    Button,
    Link,
    Text,
    Flex,
    CircularProgress,
    Stack,
    Collapse 
  //  background 
  } from '@chakra-ui/react';


export function renderProblems(problems) {
    const emProblemArray = [];
  
    for (let problemKey in problems) {
      let problem = problems[problemKey];
      let problemName = problem["problem name"].text;
      let problemTypeId = problem["problem type id"].text;
      problemName = problemName + " (Type " + problemTypeId + ")";
      const element = (
        <div key={problemKey}>
          {problemName}
          <br />
        </div>
      );
      emProblemArray.push(element);
    }
  
    return emProblemArray;
  }
  

  export function renderMdmProblems(mdmProblems) {
    return mdmProblems ? (
      <div>
        {mdmProblems.map((problem) => (
          <div key={problem.id}>
            <strong>Problem Type:</strong> {problem.id}
            <div className="indented2">
              <div>
                <strong>Description:</strong> {problem.description}
              </div>
              <div>
                <strong>Problem:</strong> {problem.problem}
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : null;
  }
  

export const connectWebSocket = (onProgress) => {
  return new Promise((resolve, reject) => {
    if (!process.env.REACT_APP_REVEDY_API_HOST) {
      reject('REACT_APP_REVEDY_API_HOST is not set');
      return;
    }

    const socket = io(process.env.REACT_APP_REVEDY_API_HOST, {
      reconnectionAttempts: 5,
      reconnectionDelay: 5000, // 5 seconds
    });

    socket.on('connect', () => {
      resolve(socket);
    });

    socket.on('connect_error', (error) => {
      reject(error);
    });

    socket.on('progress', (data) => {
      onProgress(data);
    });
  });
};
  
export function renderDiscussion(suggest) {
    let reviewElement = null;
    let score = suggest['score'];
    const defaultKey = "-";

    const keys = [
      "score",
      "analysis category 1",
      "analysis category 2",
      "analysis category 3",
      "problem category 2",
      "problem category 4,5,6,7",
      "moderate risk morbidity",
    ];

    keys.forEach((key) => {
      if (!suggest.hasOwnProperty(key)) {
        suggest[key] = defaultKey;
      }
    });

    if (score === "low") {
      reviewElement = (
        <div className="indented" key="MDMlow">
        <details key="lowToModerate" open>
          <summary>
            <b>Low vs Moderate MDM</b>
          </summary>
          <div className="indented2">
          <details key="lowToModerate Analysis" open>
            <summary>
              <b>Low vs Moderate MDM: Analysis</b>
            </summary>
              <b>Category 1 Analysis</b><br/>
              {suggest['analysis category 1'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
              <b>Category 2 Analysis</b><br/>
              {suggest['analysis category 2'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
              <b>Category 3 Analysis</b><br/>
              {suggest['analysis category 3'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
          </details>
          
          <details key="lowToModerate Problems" open>
            <summary>
              <b>Low vs Moderate MDM: Problems</b>
            </summary>
              {suggest['problem category 2'] 
               ? suggest['problem category 2'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))
            : null}
              {suggest['problem category 4,5,6,7'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
          </details>

          <details key="lowToModerate Risk" open>
            <summary>
              <b>Low vs Moderate MDM: Risk</b>
            </summary>
              {suggest['moderate risk morbidity'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
          </details>
          </div>
        </details>
        </div>
      );
    }
  
    if (score === "moderate") {
      reviewElement = (
        <div className="indented" key="MDMmoderate">
        <details key="moderateToHigh" open>
          <summary>
            <b>Moderate vs High MDM</b>
          </summary>

          <div className="indented2">

          <details key="moderateToHigh Analysis" open>
            <summary>
              <b>Moderate to High MDM: Analysis</b>
            </summary>
              <b>Category 2 Analysis</b><br/>
              {suggest['analysis category 8 or 9'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
          </details>

        
          <details key="moderateToHigh Risk" open>
            <summary>
              <b>Moderate vs High MDM: Risk</b>
            </summary>
              {suggest['high risk morbidity'].split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
              ))}
            </details>
          </div>
        </details>
        </div>
    );
    }
  
    return reviewElement;
  }

export function renderCPTScore(cptEMArray) {
    let cpt_code = cptEMArray['code']
    let score = cptEMArray['score']
  
    const cptText = `E/M: ${cpt_code} ${score} ${cptEMArray['patienttype']} patient`;
  
    return (           
          <>
          :{cpt_code}, {score} MDM, {cptEMArray['patienttype']} patient
          </>
          
    );
  }

export function renderPatient(patient) {
  return (
    <div key="PATIENT">
      {patient}
    </div>
  );
}

export function renderRisk(risk) {
    const moderateRisk = risk.moderate['reader discussion'] ?? "N/A";
    const moderateRiskScore = risk.moderate['likelihood score'] ?? "N/A";
    const highRisk = risk.high['reader discussion'] ?? "N/A";
    const highRiskScore = risk.high['likelihood score'] ?? "N/A";
  
    return (
      <div key="RISK">
        <div>
          Moderate risk discussion:
          {moderateRisk}
          (likelihood score: {moderateRiskScore})
        </div>
        <br />
        <div>
          High risk discussion:
          {highRisk}
          (likelihood score: {highRiskScore})
        </div>
      </div>
    );
  }

export function renderAnalysis(analysis) {
    const emAnalysisCatCountArray = [];
    const emAnalysisCatArray = [];
  
    for (let catKey in analysis) {
      let category = analysis[catKey];
  
      if (Object.keys(category).length > 0) {
        let count = category.count ?? "N/A";
        let items = category.items ?? "N/A";
        let lastChar = catKey.charAt(catKey.length - 1);
        emAnalysisCatCountArray[lastChar] = `Category ${lastChar} (${count} items)`;
        items = items.split("\n");
        emAnalysisCatArray[lastChar] = items.map((item, itemKey) => (
          <div key={itemKey}>
            {item}<br />
          </div>
        ));
      }
    }
  
    return emAnalysisCatCountArray.map((catCount, index) => (
      <div key={index}>
        {catCount}
        <div className="indented2">
          {emAnalysisCatArray[index]}
        </div>
      </div>
    ));
  }

export function renderDiagnosis(diagnosis) {
    const { evidence, diagnosis: diag, medication, 
        evidence_texts, diagnosis_texts, medication_texts } = diagnosis;

    const yesOrNo = (cond) => cond ? 'yes' : 'no';

    const diagText = `diagnosis: ${yesOrNo(diag.exists === 1)}`;
    const medicationText = `medication: ${yesOrNo(medication.exists === 1)}`;
    const evidenceText = `evidence: ${yesOrNo(evidence.exists === 1)}`;

    const formatTextArray = (field) =>
    field.texts && field.exists === 1 
        ? Object.values(field.texts).map((text, index) => (
            <div className="indented" key={index}>
            <b>{text.type} Text:</b> {text.content}
            </div>
        ))
        : [];

    const relatedArray = formatTextArray(evidence_texts);
    const diagnosisArray = formatTextArray(diagnosis_texts);
    const addressedArray = formatTextArray(medication_texts);

    let icd10HeaderText = ''; // Add your header formatting logic here
    const color = "red"; // Replace with your desired background color

    return (
    <details>
        <summary>
        <b>{icd10HeaderText}</b>
        </summary>
        <div>
        <span style={{ backgroundColor: color }}> {evidenceText}</span>,
        <span style={{ backgroundColor: color, textDecoration: 'underline' }}> {diagText}</span>,
        <span style={{ backgroundColor: color, fontStyle: 'italic' }}> {medicationText}</span>
        </div>
        {relatedArray}
        {diagnosisArray}
        {addressedArray}
    </details>
    );
}


export function renderCPTProcedure(cptArray) {
  //check to see if cptArray is empty or not a valid structure
  // Check if cptArray is an array and has elements
  if (!Array.isArray(cptArray) || cptArray.length === 0) {
    console.log("cptArray error", cptArray);
    return;
  }
  console.log("cptArray ok",cptArray);


  return (
      <>
          {cptArray.map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'left' }}>
                        {item.cpt_code}: {item.cpt_description}
                  <Rating />
              </div>
          ))}
      </>
  );
}


export function renderICD10(codeArray, colors, formatDetailsList,setFormatDetailsList,handleTextClick, expandedCPTSections, setExpandedCPTSections ) {
  //go through in sorted order by key
  let diagnosisGrid = [];

  // Create an array of keys sorted by the 'match' value in descending order
  let sortedKeys = Object.keys(codeArray).sort((a, b) => {
    let matchA = codeArray[a].match || 0;
    let matchB = codeArray[b].match || 0;
    return matchB - matchA;
  });

  let diagnosisCount = sortedKeys.length;
  let diagnosisGridArray = [];
  let element = null;
  for (let i = 0; i < sortedKeys.length; i++) {
    let icd_10 = sortedKeys[i];
    element = renderICD10Grid(icd_10, codeArray[icd_10], colors, formatDetailsList, setFormatDetailsList, handleTextClick, expandedCPTSections, setExpandedCPTSections);
    diagnosisGridArray.push(element);
  }

  diagnosisGrid = (
    <div key="ICD10">
    <Grid templateRows="repeat(sortedKeys.length, 1fr)" templateColumns="repeat(1, 1fr)" gap={2}>
    {diagnosisGridArray}
    </Grid>
    </div>
  );

  return diagnosisGrid;
}


export function renderICD10Grid(icd10_code, icd10_array, colors, formatDetailsList, setFormatDetailsList, handleTextClick,expandedCPTSections,  setExpandedCPTSections) {
    let icd_10 = icd10_code;
    let codeArray = {};
    codeArray[icd_10] = icd10_array;
    let element = "";
    let color = colors.shift();
    let evidence = "";
    let diagnosis = "";
    let medication = "";
    let evidence_texts = "";
    let diagnosis_texts = "";
    let medication_texts = "";
    let element_key = "";
    let formatObject = {};
    let related_text = ""
    let diagnosis_text = ""
    let addressed_text = ""
    let relatedArray = [];
    let diagnosisArray = [];
    let addressedArray = [];
    let icd10HeaderText = "";
    let icd10HCCText = "";
    let cellColor = "";

    if (typeof codeArray[icd_10]['diagnosis'] !== 'undefined' && codeArray[icd_10]['diagnosis']['exists'] === 1) {
      diagnosis = 'diagnosis: yes';
    } else {
      diagnosis = 'diagnosis:no';
    }
    if (typeof codeArray[icd_10]['medication'] !== 'undefined' && codeArray[icd_10]['medication']['exists'] === 1) {
      medication = 'medication: yes';
    } else {
      medication = 'medication:no';
    }
    if (typeof codeArray[icd_10]['evidence'] !== 'undefined' && codeArray[icd_10]['evidence']['exists'] === 1) {
      evidence = 'evidence: yes';
    } else {
      evidence = 'evidence:no';
    }

    if (typeof codeArray[icd_10]['evidence text'] !== 'undefined' && codeArray[icd_10]['evidence text']['exists'] === "1") {
      evidence_texts = codeArray[icd_10]['evidence text']['texts'];
    } else {}
    if (typeof codeArray[icd_10]['diagnosis text'] !== 'undefined' && codeArray[icd_10]['diagnosis text']['exists'] === 1) {
      diagnosis_texts = codeArray[icd_10]['diagnosis text']['texts'];
    } else {}
    if (typeof codeArray[icd_10]['medication text'] !== 'undefined' && codeArray[icd_10]['medication text']['exists'] === 1) {
      medication_texts = codeArray[icd_10]['medication text']['texts'];
    } else {}

    if (evidence === 'evidence: yes' || diagnosis === 'diagnosis: yes' || medication === 'medication: yes') {  
      if (codeArray[icd_10]['icd-10 code']['text'].toUpperCase() === "NONE") {
      }
      else {
        let matchText = codeArray[icd_10]['match'];
        //convert matchText to int
        let matchTextInt = parseInt(matchText);
        if (matchTextInt > 95) {
            matchText = '> 95%';
            cellColor = "#3F9042";
          }
          else if (matchTextInt > 60) {
            matchText = '> 60%';
            cellColor = "#70A03F";
          }
          else if (matchTextInt > 9) {
            matchText = '<60%';
            cellColor = "#E6B000";
          }
        else {
          return;
          //matchText = 'Unlikely - '+matchText;
        }
        matchText = matchText + ' : ';
        //check if hcc is in the codeArray[icd_10]
        if (typeof codeArray[icd_10]['hcc'] === 'undefined') {
          icd10HeaderText = matchText + codeArray[icd_10]['icd-10 code']['text'];
          icd10HCCText = "HCC: none";
        }
        else {
          icd10HeaderText = matchText + codeArray[icd_10]['icd-10 code']['text'];
          icd10HCCText = "HCC: " + codeArray[icd_10]['hcc']['hcc_code']+ " - " + codeArray[icd_10]['hcc']['hcc_description'] + " (" + codeArray[icd_10]['hcc']['hcc_weight'] + ")";
        }
      }
      related_text = ""
      diagnosis_text = ""
      addressed_text = ""


      //ok here we generate the clickable text, first push the prior text onto the array

      if (evidence === 'evidence: yes') {
        related_text = "related text"; 
      }
      if (diagnosis === 'diagnosis: yes') {
        diagnosis_text = "diagnosis text";
      }
      if (medication === 'medication: yes') {
        addressed_text = "addressed text";
      }
      
      element_key  = icd_10 + "ClickableText";
      formatDetailsList[element_key] = [];

      for (let t in codeArray[icd_10]['evidence text']['texts']) {
        element = (
          <div className="indented"><b>Related Text:</b> {t}</div>
        );
        relatedArray.push(element);
      }
      for (let t in codeArray[icd_10]['diagnosis text']['texts']) {
        element = (
          <div className="indented"><b>Diagnosis Text:</b> {t}</div>
        );
        diagnosisArray.push(element);
      }
      for (let t in codeArray[icd_10]['medication text']['texts']) {
        element = (
          <div className="indented"><b>Addressed Text:</b> {t}</div>
        );
        addressedArray.push(element);
      }
      
      if (typeof codeArray[icd_10]['diagnosis text']['texts'] !== 'undefined') {
        for (let t in codeArray[icd_10]['diagnosis text']['texts']) {
          let start = codeArray[icd_10]['diagnosis text']['texts'][t]['start'];
          let end = codeArray[icd_10]['diagnosis text']['texts'][t]['end'];
          let length = end - start;
          formatObject = { start: start, end: end, color: color, textDecoration:'underline', fontStyle: '' };
          formatDetailsList[element_key].push(formatObject);
        }
      }
      if (typeof codeArray[icd_10]['evidence text']['texts'] !== 'undefined') {  //this is related text
        for (let t in codeArray[icd_10]['evidence text']['texts']) {
          let start = codeArray[icd_10]['evidence text']['texts'][t]['start'];
          let end = codeArray[icd_10]['evidence text']['texts'][t]['end'];
          let length = end - start;
          //quill.setSelection(start, length);
          //quill.format('background', color);
          formatObject = { start: start, end: end, color: color, textDecoration:'', fontStyle: '' };
          formatDetailsList[element_key].push(formatObject); 
        }
      }
      if (typeof codeArray[icd_10]['medication text']['texts'] !== 'undefined') {
        for (let t in codeArray[icd_10]['medication text']['texts']) {
          let start = codeArray[icd_10]['medication text']['texts'][t]['start'];
          let end = codeArray[icd_10]['medication text']['texts'][t]['end'];
          let length = end - start;
          //quill.setSelection(start, length);
          //quill.format('background', color);
          //quill.format('italic', true);
          //quill.setSelection(0, 0);
          formatObject = { start: start, end: end, color: color, textDecoration:'', fontStyle: 'italic' };
          formatDetailsList[element_key].push(formatObject);
        }
      }

      setFormatDetailsList(formatDetailsList);





      let detail = (
        <details>
        <summary>
          <b>Details</b>
        </summary>
        <div>
          <div key={element_key} onClick={(e) => handleTextClick(element_key,formatDetailsList,e)}>                    
            <span style={{ backgroundColor: color }}> {related_text}</span>,
            <span style={{ backgroundColor: color, textDecoration: 'underline' }}> {diagnosis_text}</span>,
            <span style={{ backgroundColor: color, fontStyle: 'italic' }}> {addressed_text}</span> 
               (Click to view in note)
          </div>
          <div className="indented">
    
    
    
    <details>
      <summary>
        <b>MEAT Analysis:</b> <br />
      </summary>
      {codeArray[icd_10]?.['aemt_array']?.['monitor_rec']?.[0] && (
        <>
          <b>Monitor: (scored {codeArray[icd_10]['aemt_array']['monitor_rec'][0]['list']['score']}/100)</b> <br />  {codeArray[icd_10]['aemt_array']['monitor'][0]['list']['monitoring text']} <br />
          <b>Possibly consider:</b> {codeArray[icd_10]['aemt_array']['monitor_rec'][0]['list']['additional text']} <br />
        </>
      )}
      
      {codeArray[icd_10]?.['aemt_array']?.['evaluate_rec']?.[0] && (
        <>
          <b>Evaluate:(scored {codeArray[icd_10]['aemt_array']['evaluate_rec'][0]['list']['score']}/100)</b> <br />  {codeArray[icd_10]['aemt_array']['evaluate'][0]['list']['evaluation text']} <br />
          <b>Possibly consider:</b> {codeArray[icd_10]['aemt_array']['evaluate_rec'][0]['list']['additional text']} <br />
        </>
      )}
      
      {Array.isArray(codeArray[icd_10]?.['aemt_array']?.['assess_rec']?.[0]) && (
        <>
          <b>Assessment: (scored {codeArray[icd_10]['aemt_array']['assess_rec'][0]['list']['score']}/100)</b> <br />
          {codeArray[icd_10]['aemt_array']['assess'][0]['list']['assessment text']}<br />
          <b>Possibly consider:</b> {codeArray[icd_10]['aemt_array']['assess_rec'][0]['list']['additional text']}<br />
        </>
      )}
      
      {codeArray[icd_10]?.['aemt_array']?.['treat_rec']?.[0] && (
        <>
          <b>Treatment: (scored {codeArray[icd_10]['aemt_array']['treat_rec'][0]['list']['score']}/100)</b> <br />  {codeArray[icd_10]['aemt_array']['treat'][0]['list']['treatment text']} <br />
          <b>Possibly consider:</b> {codeArray[icd_10]['aemt_array']['treat_rec'][0]['list']['additional text']} <br />
        </>
      )}
      
      <b>Discussion:</b> {codeArray[icd_10]?.['match percentage']} <br />
    </details>

          </div>
            {relatedArray}
            {diagnosisArray}
            {addressedArray}
        </div>
        </details>
      );


      element = (
        <GridItem key={icd_10 + expandedCPTSections[icd_10]} bg="white" borderRadius={3} boxShadow="md" padding={2}>
          <Text as="div" fontSize="md" fontWeight="normal" align={"left"}>
            <Flex alignItems="center">
              <Box marginRight={4}>{icd_10}</Box>
              <Button
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  maxHeight: '600px',
                  overflowY: 'scroll',
                  whiteSpace: 'pre-wrap',
                }}
                colorScheme="blue"
                variant="link"
                
                onClick={() => {
                  setExpandedCPTSections(prevState => ({
                    ...prevState,
                    [icd_10]: !prevState[icd_10]
                  }));
                }}
              >
                {" "}Details{" "}
              </Button>
              <Rating />
            </Flex>            
            <div
              className="progress"
              style={{
                textAlign: 'left',
                fontSize: '12px',
                fontWeight: 'normal',
                maxHeight: '600px',
                overflowY: 'scroll',
                whiteSpace: 'pre-wrap',
              }}
            >
              {icd10HeaderText}
              <br />
              {icd10HCCText}
            </div>
            <div
                className="selectedData"
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  whiteSpace: 'pre-wrap',
                  paddingLeft: '20px',
                }}
              > 
          {detail}
          </div>
          {expandedCPTSections[icd_10] && (
              <div
                className="selectedData"
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  whiteSpace: 'pre-wrap',
                  paddingLeft: '20px',
                }}
              >
              </div>
            )}
                      </Text>

        </GridItem>
      );
    }
    else {
        return;
    }
    return element;
}   


///
