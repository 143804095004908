import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Box,
  Button,
  Input,
  HStack,
  Text,
  Heading,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  VStack,
  Link,
  Select, // Imported Select component
} from '@chakra-ui/react';
import HeaderMenus from './HeaderMenus';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';

const MedicalNecessityGeneral = () => {
  const { accessToken } = useContext(AppContext);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [selectedCptCode, setSelectedCptCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [CPTCodesData, setCPTCodesData] = useState({});

  // State variables for form inputs
  const [caseId, setCaseId] = useState('');
  const [cptCode, setCptCode] = useState('');
  const [payer, setPayer] = useState('cms'); // Added payer state
  const [analysisPayer, setAnalysisPayer] = useState(''); // To store the payer used in analysis
  const [caseOverview, setCaseOverview] = useState(null);
  const [isGeneratingOverview, setIsGeneratingOverview] = useState(false);



  const handleGenerateOverview = async () => {
    setIsGeneratingOverview(true);
    try {
      const response = await callApi('/generate_case_overview', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ encounter_id: caseId }),
      });
      const data = await response.json();
      if (data.case_overview) {
        setCaseOverview(data.case_overview);
      } else {
        console.error('Error generating case overview:', data.error);
      }
    } catch (error) {
      console.error('Error generating case overview:', error);
    } finally {
      setIsGeneratingOverview(false);
    }
  };


  const addLinksToReferences = (text) => {
    return text.replace(/\b(A\d+|LCD\d+|https:\/\/www.aetna.com\/cpb\/medical\/data.*.html)\b/g, (match) => {

        if (match.startsWith('A') && !match.startsWith('Aetna_')) {
        // Handle CMS articles
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/article.aspx?articleId=${match.slice(
          1
        )})`;
      } else if (match.startsWith('LCD')) {
        // Handle LCD links
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/lcd.aspx?LCDId=${match.slice(
          3
        )})`;
        } else if (match.startsWith('https://www.aetna.com/cpb/medical/data/')) {
            // Return the URL as a markdown link
            return `[${match}](${match})`;
        }
      
      return match;
    });
  };
  
  
  const LinkRenderer = ({ href, children }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" color="blue.500">
      {children}
    </Link>
  );

  const fetchAnalysis = async (payload) => {
    setIsLoading(true);
    try {
      const response = await callApi('/medical_necessity_general', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status === 'success') {
        console.log('Analysis results:', data);
        setCPTCodesData(data.cpt_codes_data);
        if (!payload.codes_only) {
          setAnalysisResults(data.results);
          setAnalysisPayer(payload.payer);
          if (data.results.length > 0) {
            setSelectedCptCode(data.results[0].cpt_code);
          }
        }
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event, submittedCaseId = null, submittedCodesOnly = false, submittedPayer = null) => {
    if (event && event.preventDefault) event.preventDefault();       
    const payload = {
        case_id: submittedCaseId || caseId,
        payer: submittedPayer || payer,
        codes_only: submittedCodesOnly || false,
        };
    fetchAnalysis(payload);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const case_id = searchParams.get('case_id') || '';
        const payer_param = searchParams.get('payer') || 'cms';
        const codes_only = searchParams.get('codes_only') || true;
      
        if (case_id) setCaseId(case_id);
        if (payer_param) setPayer(payer_param);
      
        if (case_id) {
          handleSubmit(null, case_id, codes_only, payer_param);
        }
      }, []);


  // Component to display sources list
  const SourcesList = ({ sources, title }) => (
    <Box>
      <Heading as="h3" size="md" mb={2}>
        {title}
      </Heading>
      {sources.map((source, index) => (
        <Box key={index} mb={4} p={4} borderWidth={1} borderRadius="md" boxShadow="sm">
          
          <ReactMarkdown components={{ a: LinkRenderer }}>
          {addLinksToReferences(source.source_id)}
            </ReactMarkdown>

          <Text mt={2}>{source.relevancy_explanation}</Text>

        </Box>
      ))}
    </Box>
  );

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const renderCptCodes = () => {
    if (!CPTCodesData || Object.keys(CPTCodesData).length === 0) {
      return <Text>No CPT codes found</Text>;
    }
  
    // Convert CPTCodesData to an array of CPT code objects
    const cptCodesArray = Object.values(CPTCodesData);
  
    return (
      <VStack align="stretch" spacing={2}>
        <Heading as="h3" size="md" mb={2}>
          CPT Codes
        </Heading>
        {cptCodesArray
          .sort((a, b) => {
            // Sort by non-facility reimbursement in descending order
            const reimbA = a.estimated_non_facility_reimbursement || 0;
            const reimbB = b.estimated_non_facility_reimbursement || 0;
            return reimbB - reimbA;
          })
          .map((item, index) => {
            // Find the analysis for the current CPT code
            const analysis = analysisResults.find(
              (result) => result.cpt_code === item.cpt_code
            );
            const score = analysis ? analysis.analysis.score : 'N/A';
  
            return (
              <Box key={item.cpt_code} p={2} borderWidth={1} borderRadius="md">
                <HStack justifyContent="space-between">
                  <Link
                    onClick={() => setSelectedCptCode(item.cpt_code)}
                    color="blue.500"
                    cursor="pointer"
                  >
                    {item.cpt_code}
                  </Link>
                  <Text fontSize="sm" fontWeight="bold">
                    Score: {score}
                  </Text>
                </HStack>
                <Text fontSize="sm">
                  {truncateText(item.long_description || '', 100)}
                </Text>
                <VStack align="start" mt={2} spacing={0}>
                  <Text fontSize="xs">Work RVU: {item.work_rvu || 'N/A'}</Text>
                  <Text fontSize="xs">Non-Facility Total: {item.non_facility_total || 'N/A'}</Text>
                  <Text fontSize="xs">Facility Total: {item.facility_total || 'N/A'}</Text>
                  <Text fontSize="xs">Est. Non-Facility Reimb: ${item.estimated_non_facility_reimbursement?.toFixed(2) || 'N/A'}</Text>
                  <Text fontSize="xs">Est. Facility Reimb: ${item.estimated_facility_reimbursement?.toFixed(2) || 'N/A'}</Text>
                </VStack>
              </Box>
            );
          })}
      </VStack>
    );
  };

  const selectedAnalysis = analysisResults.find(
    (result) => result.cpt_code === selectedCptCode
  )?.analysis;

  return (
    <Box>
      <HeaderMenus />
      <Container maxW="container.xl" py={8}>
        <Heading as="h1" mb={6}>
          Medical Necessity Analysis
        </Heading>
        <form onSubmit={handleSubmit}>
          <HStack spacing={4} mb={4}>
            <Input
              name="case_id"
              placeholder="Case ID"
              required
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              width="200px"
            />
            <Select
              name="payer"
              value={payer}
              onChange={(e) => setPayer(e.target.value)}
              width="200px"
            >
              <option value="cms">Medicare</option>
              <option value="aetna">Aetna</option>
            </Select>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Analyze
            </Button>
            <Button
              onClick={handleGenerateOverview}
              colorScheme="green"
              isLoading={isGeneratingOverview}
            >
              Generate Overview
            </Button>
          </HStack>
        </form>

        {CPTCodesData && Object.keys(CPTCodesData).length > 0 && (
          <Grid templateColumns="250px 1fr" gap={6}>
            <GridItem>{renderCptCodes()}</GridItem>
            <GridItem>
              {selectedAnalysis && (
                <Box>
                  <Tabs>
                    <TabList>
                      <Tab>Analysis Results</Tab>
                      <Tab>Sources</Tab>
                      <Tab>Case Overview</Tab>
                    </TabList>

                    <TabPanels>


                      <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                          Analysis Results for {selectedCptCode}
                        </Heading>
                        <Text mb={4}>
                          Payer:{' '}
                          {analysisPayer === 'cms'
                            ? 'Medicare'
                            : analysisPayer === 'aetna'
                            ? 'Aetna'
                            : analysisPayer}
                        </Text>
                        <Text fontWeight="bold" mb={2}>
                          Explanation:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.explanation)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mt={4} mb={2}>
                          Key Factors:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.key_factors)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mb={2}>
                          Recommendations:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.recommendations)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold">Score: {selectedAnalysis.score}</Text>
                      </TabPanel>



                      <TabPanel>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.used_sources}
                              title="Used Sources"
                            />
                          </GridItem>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.unused_sources}
                              title="Unused Sources"
                            />
                          </GridItem>
                        </Grid>
                      </TabPanel>


                      <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                          Case Overview
                        </Heading>
                        
                        {caseOverview ? (
                            <ReactMarkdown>
                              {caseOverview.overview}
                            </ReactMarkdown>
                        ) : (
                          <Text>No case overview available. Click "Generate Overview" to create one.</Text>
                        )}
                      </TabPanel>



                    </TabPanels>
                  </Tabs>
                </Box>
              )}
            </GridItem>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default MedicalNecessityGeneral;