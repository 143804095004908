//AdminReview.js
import React, { useState, useEffect, useContext } from 'react';
import { 
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Textarea, Button, Select,
  Heading, useToast, Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function AdminReview() {
    const { encounterId } = useParams();
    const [originalTranscript, setOriginalTranscript] = useState('');
    const [originalNote, setOriginalNote] = useState('');
    const [cleanTranscript, setCleanTranscript] = useState('');
    const [cleanNote, setCleanNote] = useState('');
    const [encounterDetails, setEncounterDetails] = useState(null);
    const [encounterCodes, setEncounterCodes] = useState([]);
    const [reviewers, setReviewers] = useState([]);
    const [selectedReviewer, setSelectedReviewer] = useState('');
    const { accessToken } = useContext(AppContext);
    const toast = useToast();

    useEffect(() => {
        fetchEncounterDetails();
        fetchReviewers();
    }, [encounterId]);


    const cleanContent = async (content) => {
      try {
        const response = await callApi('/clean_content', accessToken, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content }),
        });
    
        if (response.ok) {
          const data = await response.json();
          return data.clean_text;
        } else {
          throw new Error('Failed to clean content');
        }
      } catch (error) {
        console.error('Error cleaning content:', error);
        toast({
          title: 'Error cleaning content',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return content; // Return original content if cleaning fails
      }
    };

    const fetchEncounterDetails = async () => {
      try {
        const response = await callApi(`/encounter_details/${encounterId}`, accessToken);
        if (response.ok) {
          const data = await response.json();
          setEncounterDetails(data);
          setOriginalTranscript(data.originalTranscript || '');
          setOriginalNote(data.originalNote || '');
    
          // Clean the transcript and note
          const cleanedTranscript = await cleanContent(data.originalTranscript || '');
          const cleanedNote = await cleanContent(data.originalNote || '');
    
          setCleanTranscript(cleanedTranscript);
          setCleanNote(cleanedNote);
          setEncounterCodes(data.encounterCodes || []);
        } else {
          throw new Error('Failed to fetch encounter details');
        }
      } catch (error) {
        console.error('Error fetching encounter details:', error);
        toast({
          title: 'Error fetching encounter details',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    

    const fetchEncounterDetails_orig = async () => {
        try {
          const response = await callApi(`/encounter_details/${encounterId}`, accessToken);
          if (response.ok) {
            const data = await response.json();
            setEncounterDetails(data);
            setOriginalTranscript(data.originalTranscript || '');
            setOriginalNote(data.originalNote || '');
            setCleanTranscript(data.cleanTranscript || data.originalTranscript || '');
            setCleanNote(data.cleanNote || data.originalNote || '');
            setEncounterCodes(data.encounterCodes || []);
          } else {
            throw new Error('Failed to fetch encounter details');
          }
        } catch (error) {
          console.error('Error fetching encounter details:', error);
          toast({
            title: 'Error fetching encounter details',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };



      const fetchReviewers = async () => {
        try {
            const response = await callApi('/reviewers', accessToken);
            if (response.ok) {
                const data = await response.json();
                setReviewers(data);
            } else {
                throw new Error('Failed to fetch reviewers');
            }
        } catch (error) {
            console.error('Error fetching reviewers:', error);
            toast({
                title: 'Error fetching reviewers',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSaveAndAssign = async () => {
        try {
            const response = await callApi('/save_and_assign_review', accessToken, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    encounterId,
                    cleanTranscript,
                    cleanNote,
                    cleanCodes: encounterCodes,
                    assigneeEmail: selectedReviewer
                }),
            });

            if (response.ok) {
                toast({
                    title: 'Review saved and assigned successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error('Failed to save and assign review');
            }
        } catch (error) {
            console.error('Error saving and assigning review:', error);
            toast({
                title: 'Error saving and assigning review',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };
  
    return (
      <ChakraProvider theme={theme}>
        <Box>
          <Grid templateAreas={`"header" "main" "footer"`} gridTemplateRows={'60px 1fr 60px'} gridTemplateColumns={'1fr'} h='100vh' gap='1' color='blackAlpha.800'>
            <GridItem area={'header'}>
              <HeaderMenus />
            </GridItem>

            <GridItem bg='gray.50' area={'main'} overflowY="auto">
  <Box p={4}>
    <VStack spacing={4} align="stretch">
      <Heading mb={4}>Admin Review - Encounter {encounterId}</Heading>
      
      {encounterDetails && (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>External Encounter ID</Th>
              <Th>External Patient ID</Th>
              <Th>Encounter Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{encounterDetails.external_encounter_id}</Td>
              <Td>{encounterDetails.external_patient_id}</Td>
              <Td>{new Date(encounterDetails.encounter_date).toLocaleDateString()}</Td>
            </Tr>
          </Tbody>
        </Table>
      )}

      <Box>
        <Heading size="md" mb={4}>Transcript</Heading>
        <Flex>
          <Box flex={1} mr={4}>
            <Text fontSize="sm" mb={2}>Original:</Text>
            <Textarea
              value={originalTranscript}
              isReadOnly
              height="1000px"
              resize="none"
              borderColor="gray.300"
            />
          </Box>
          <Box flex={1}>
            <Text fontSize="sm" mb={2}>Clean Version:</Text>
            <Textarea
              value={cleanTranscript}
              onChange={(e) => setCleanTranscript(e.target.value)}
              height="1000px"
              resize="none"
              borderColor="gray.300"
            />
          </Box>
        </Flex>
      </Box>

      <Box mt={8}>
        <Heading size="md" mb={4}>Note</Heading>
        <Flex>
          <Box flex={1} mr={4}>
            <Text fontSize="sm" mb={2}>Original:</Text>
            <Textarea
              value={originalNote}
              isReadOnly
              height="1000px"
              resize="none"
              borderColor="gray.300"
            />
          </Box>
          <Box flex={1}>
            <Text fontSize="sm" mb={2}>Clean Version:</Text>
            <Textarea
              value={cleanNote}
              onChange={(e) => setCleanNote(e.target.value)}
              height="1000px"
              resize="none"
              borderColor="gray.300"
            />
          </Box>
        </Flex>
      </Box>







              <Box mt={8}>
                <Heading size="md" mb={4}>Encounter Codes</Heading>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Type</Th>
                      <Th>Code</Th>
                      <Th>Confidence Score</Th>
                      <Th>Source</Th>
                      <Th>Status</Th>
                      <Th>Notes</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {encounterCodes.map((code, index) => (
                      <Tr key={index}>
                        <Td>{code.type}</Td>
                        <Td>{code.code}</Td>
                        <Td>{code.confidence_score}</Td>
                        <Td>{code.source}</Td>
                        <Td>{code.status}</Td>
                        <Td>{code.status_notes}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>




            <Select
                placeholder="Select reviewer"
                value={selectedReviewer}
                onChange={(e) => setSelectedReviewer(e.target.value)}
                mt={4}
            >
                {reviewers.map(reviewer => (
                    <option key={reviewer} value={reviewer}>{reviewer}</option>
                ))}
            </Select>
            <Button onClick={handleSaveAndAssign} colorScheme="blue" mt={4} isDisabled={!selectedReviewer}>
                Save and Assign Review
            </Button>


    </VStack>
  </Box>
</GridItem>



            <GridItem bg='white' borderTop='1px' borderColor='brand.200' area={'footer'}>
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
  
          <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_admin_review'}
            contextPersona={'revedy_admin_review'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
          />
        </Box>
      </ChakraProvider>
    );
  }
  
  export default AdminReview;