import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Box,
  Button,
  Input,
  HStack,
  Text,
  Heading,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  VStack,
  Textarea,
  Collapse,
  useDisclosure,
  Link,
  Select, // Imported Select component
} from '@chakra-ui/react';
import HeaderMenus from './HeaderMenus';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';

const MedicalNecessityAnalysis = () => {
  const { accessToken } = useContext(AppContext);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [selectedCptCode, setSelectedCptCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [CPTCodesData, setCPTCodesData] = useState({});
  const [feedback, setFeedback] = useState('');
  const [encounterFeedback, setEncounterFeedback] = useState([]);
  const { isOpen, onToggle } = useDisclosure();

  // State variables for form inputs
  const [caseId, setCaseId] = useState('');
  const [cptCode, setCptCode] = useState('');
  const [payer, setPayer] = useState('cms'); // Added payer state
  const [analysisPayer, setAnalysisPayer] = useState(''); // To store the payer used in analysis


  useEffect(() => {
    if (caseId) {
      fetchEncounterFeedback();
    }
  }, [caseId, payer]);

  const addLinksToReferences = (text) => {
    return text.replace(/\b(A\d+|LCD\d+|https:\/\/www.aetna.com\/cpb\/medical\/data.*.html)\b/g, (match) => {

        if (match.startsWith('A') && !match.startsWith('Aetna_')) {
        // Handle CMS articles
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/article.aspx?articleId=${match.slice(
          1
        )})`;
      } else if (match.startsWith('LCD')) {
        // Handle LCD links
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/lcd.aspx?LCDId=${match.slice(
          3
        )})`;
        } else if (match.startsWith('https://www.aetna.com/cpb/medical/data/')) {
            // Return the URL as a markdown link
            return `[${match}](${match})`;
        }
      
      return match;
    });
  };
  
  
  const LinkRenderer = ({ href, children }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" color="blue.500">
      {children}
    </Link>
  );

  const handleFeedbackSubmit = async () => {
    try {
      const response = await callApi('/submit_medical_necessity_feedback', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          case_id: caseId,
          encounter_id: caseId,
          cpt_code: selectedCptCode,
          feedback_text: feedback,
          payer: payer  // Include the payer
        }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        alert('Medical necessity feedback submitted successfully');
        setFeedback('');
        fetchEncounterFeedback();
      }
    } catch (error) {
      console.error('Error submitting medical necessity feedback:', error);
    }
  };
  
  const fetchEncounterFeedback = async () => {
    try {
      const response = await callApi(`/get_medical_necessity_feedback/${caseId}?payer=${payer}`, accessToken);
      const data = await response.json();
      if (data.status === 'success') {
        setEncounterFeedback(data.feedback);
      }
    } catch (error) {
      console.error('Error fetching medical necessity feedback:', error);
    }
  };

  // Function to perform the API call
  const fetchAnalysis = async (payload) => {
    setIsLoading(true);
    try {
      const response = await callApi('/medical_necessity_analysis', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setAnalysisResults(data.results);
        setCPTCodesData(data.cpt_codes_data);
        setAnalysisPayer(payload.payer); // Store the payer used in analysis
        if (data.results.length > 0) {
          setSelectedCptCode(data.results[0].cpt_code);
        }
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = { case_id: caseId, cpt_code: cptCode, payer }; // Include payer in payload
    fetchAnalysis(payload);
  };

  // Parse query parameters on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const case_id = searchParams.get('case_id') || '';
    const cpt_code = searchParams.get('cpt_code') || '';
    const payer_param = searchParams.get('payer') || 'cms'; // Get payer from query params or default to 'cms'

    if (case_id) {
      setCaseId(case_id);
    }
    if (cpt_code) {
      setCptCode(cpt_code);
    }
    if (payer_param) {
      setPayer(payer_param);
    }

    // If query parameters are present, automatically fetch analysis
    if (case_id || cpt_code) {
      const payload = { case_id, cpt_code, payer: payer_param };
      fetchAnalysis(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Component to display sources list
  const SourcesList = ({ sources, title }) => (
    <Box>
      <Heading as="h3" size="md" mb={2}>
        {title}
      </Heading>
      {sources.map((source, index) => (
        <Box key={index} mb={4} p={4} borderWidth={1} borderRadius="md" boxShadow="sm">
          
          <ReactMarkdown components={{ a: LinkRenderer }}>
          {addLinksToReferences(source.source_id)}
            </ReactMarkdown>

          <Text mt={2}>{source.relevancy_explanation}</Text>

        </Box>
      ))}
    </Box>
  );

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  // Render CPT codes list
  const renderCptCodes = () => {
    if (!CPTCodesData || Object.keys(CPTCodesData).length === 0) {
      return null;
    }

    return (
      <VStack align="stretch" spacing={2}>
        <Heading as="h3" size="md" mb={2}>
          CPT Codes
        </Heading>
        {/* Tech Section */}
        {CPTCodesData.tech && (
          <Box>
            <Text fontWeight="bold" mt={4} mb={2}>
              Tech
            </Text>
            {Object.values(CPTCodesData.tech)
              .sort((a, b) => b.rvuscore - a.rvuscore)
              .map((item, index) => {
                // Find the analysis for the current CPT code
                const analysis = analysisResults.find(
                  (result) => result.cpt_code === item.cpt_code_no_modifier
                );
                const score = analysis ? analysis.analysis.score : 'N/A';

                return (
                  <Box key={index} p={2} borderWidth={1} borderRadius="md">
                    <HStack justifyContent="space-between">
                      <Link
                        onClick={() => setSelectedCptCode(item.cpt_code_no_modifier)}
                        color="blue.500"
                        cursor="pointer"
                      >
                        {item.cpt_code}
                      </Link>
                      <Text fontSize="sm" fontWeight="bold">
                        Score: {score}
                      </Text>
                    </HStack>
                    <Text fontSize="sm">{truncateText(item.cpt_description, 100)}</Text>
                  </Box>
                );
              })}
          </Box>
        )}

        {/* Pro Section */}
        {CPTCodesData.pro && (
          <Box>
            <Text fontWeight="bold" mt={4} mb={2}>
              Pro
            </Text>
            {Object.values(CPTCodesData.pro)
              .sort((a, b) => b.rvuscore - a.rvuscore)
              .map((item, index) => {
                // Find the analysis for the current CPT code
                const analysis = analysisResults.find(
                  (result) => result.cpt_code === item.cpt_code_no_modifier
                );
                const score = analysis ? analysis.analysis.score : 'N/A';

                return (
                  <Box key={index} p={2} borderWidth={1} borderRadius="md">
                    <HStack justifyContent="space-between">
                      <Link
                        onClick={() => setSelectedCptCode(item.cpt_code_no_modifier)}
                        color="blue.500"
                        cursor="pointer"
                      >
                        {item.cpt_code}
                      </Link>
                      <Text fontSize="sm" fontWeight="bold">
                        Score: {score}
                      </Text>
                    </HStack>
                    <Text fontSize="sm">{truncateText(item.cpt_description, 100)}</Text>
                  </Box>
                );
              })}
          </Box>
        )}
      </VStack>
    );
  };

  const selectedAnalysis = analysisResults.find(
    (result) => result.cpt_code === selectedCptCode
  )?.analysis;

  return (
    <Box>
      <HeaderMenus />
      <Container maxW="container.xl" py={8}>
        <Heading as="h1" mb={6}>
          Medical Necessity Analysis
        </Heading>
        <form onSubmit={handleSubmit}>
          <HStack spacing={4} mb={4}>
            <Input
              name="case_id"
              placeholder="Case ID"
              required
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              width="200px"
            />
            <Input
              name="cpt_code"
              placeholder="CPT Code (optional)"
              value={cptCode}
              onChange={(e) => setCptCode(e.target.value)}
              width="200px"
            />
            <Select
              name="payer"
              value={payer}
              onChange={(e) => setPayer(e.target.value)}
              width="200px"
            >
              <option value="cms">Medicare</option>
              <option value="aetna">Aetna</option>
            </Select>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Analyze
            </Button>
          </HStack>
        </form>

        {analysisResults.length > 0 && (
          <Grid templateColumns="250px 1fr" gap={6}>
            <GridItem>{renderCptCodes()}</GridItem>
            <GridItem>
              {selectedAnalysis && (
                <Box>


<Box mt={6}>
  <Button onClick={onToggle} mb={2}>
    {isOpen ? "Hide Feedback" : "Show Feedback"}
  </Button>
  <Collapse in={isOpen} animateOpacity>
    <Box border="1px" borderColor="gray.200" borderRadius="md" p={4}>
      <Heading as="h3" size="md" mb={2}>
        Submit Feedback
      </Heading>
      <Textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Enter your feedback here"
        mb={2}
      />
      <Button onClick={handleFeedbackSubmit} colorScheme="blue">
        Submit Feedback
      </Button>

      <Box mt={6}>
        <Heading as="h3" size="md" mb={2}>
          Encounter Feedback
        </Heading>
        {encounterFeedback.map((item) => (
          <Box key={item.id} mb={4} p={4} borderWidth={1} borderRadius="md">
            <Text><strong>CPT Code:</strong> {item.cpt_code}</Text>
            <Text><strong>Feedback:</strong> {item.feedback_text}</Text>
            <Text><strong>Date:</strong> {new Date(item.created_at).toLocaleString()}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  </Collapse>
</Box>









                  <Tabs>
                    <TabList>
                      <Tab>Analysis Results</Tab>
                      <Tab>Sources</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                          Analysis Results for {selectedCptCode}
                        </Heading>
                        <Text mb={4}>
                          Payer:{' '}
                          {analysisPayer === 'cms'
                            ? 'Medicare'
                            : analysisPayer === 'aetna'
                            ? 'Aetna'
                            : analysisPayer}
                        </Text>
                        <Text fontWeight="bold" mb={2}>
                          Explanation:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.explanation)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mt={4} mb={2}>
                          Key Factors:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.key_factors)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mb={2}>
                          Recommendations:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.recommendations)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold">Score: {selectedAnalysis.score}</Text>
                      </TabPanel>
                      <TabPanel>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.used_sources}
                              title="Used Sources"
                            />
                          </GridItem>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.unused_sources}
                              title="Unused Sources"
                            />
                          </GridItem>
                        </Grid>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              )}
            </GridItem>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default MedicalNecessityAnalysis;