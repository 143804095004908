import React, { useMemo, useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import CreateUser from './CreateUser';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';



import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { setIn } from 'formik';
import { PieChart, Pie, Cell } from 'recharts';
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { WebSocketProvider, useWebSocket } from './WebSocket'; 


// ... other code ...
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
 

function Admin() {
    const [workflowSummary, setWorkflowSummary] = useState(null);
    const [sortedTasks, setSortedTasks] = useState([]);
    const [userActivitySummary, setUserActivitySummary] = useState(null);
    const [userActivityDetails, setUserActivityDetails] = useState(null);
    const [chatDetails, setChatDetails] = useState(null);
    const [chatSummaries, setChatSummaries] = useState(null);
    const [functionSummaries, setFunctionSummaries] = useState(null);
    const navigate = useNavigate();
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [showCreateUser, setShowCreateUser] = useState(false);


    const { socket } = useWebSocket();

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isImpersonating, setIsImpersonating] = useState(false);
    
    const handleViewUsersButtonClick = async () => {
        const response = await callApi('/get_user_list', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        setUsers(data);
    };
    
    const handleStartImpersonation = async (userId) => {
        const response = await callApi('/start_impersonation', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ impersonated_user_id: userId }),
        });
        if (response.ok) {
            setIsImpersonating(true);
            setSelectedUser(users.find(user => user.id === userId));
        }
    };
    
    const handleStopImpersonation = async () => {
        const response = await callApi('/stop_impersonation', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            setIsImpersonating(false);
            setSelectedUser(null);
        }
    };
    
    useEffect(() => {
        if (workflowSummary) {
            const tasks = [...workflowSummary.tasks];
            tasks.sort((a, b) => a.id - b.id); // Sort by id
            setSortedTasks(tasks);
        }
    }, [workflowSummary]);

    const resetSections = () => {
        setUserActivityDetails(null);
        setUserActivitySummary(null);
        setWorkflowSummary(null);
        setChatDetails(null);
        setChatSummaries(null);

        // Add more sections here as needed
    };

    const handleViewCodeSummariesButtonClick = async (event) => {
        console.log("View code summaries button clicked");
        const response = await callApi('/all_function_summaries', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("View code summaries response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        setFunctionSummaries(data);
    }
    

    const handleChatSummariesButtonClick = async (event) => {
        console.log("Chat summaries button clicked");
        const response = await callApi('/admin_org_chat_summary', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },            
        });
        console.log("Chat summaries response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("Chat summaries data: ", data);
    
        resetSections();
        setChatSummaries(data);
    }


    const handleChatDetailsButtonClick = async (event) => {
        const response = await callApi('/admin_ionm_chat', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Chat details response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("Chat details data: ", data);
    
        resetSections();
        setChatDetails(data);
    }


    const handleJSUpdateCodeSummariesButtonClick = async (event) => {
        const response = await callApi('/javascript_function_summaries', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        // Parse the response as JSON
        const data = await response.json();
    
    }

    
    const handleUpdateCodeSummariesButtonClick = async (event) => {
        const response = await callApi('/function_summaries', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        // Parse the response as JSON
        const data = await response.json();
    
    }

    const handleUserDetailsButtonClick = async (event) => {
        const response = await callApi('/user_activity_detail', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("User activity details response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("User activity details data: ", data);
    
        // You can now use the data in your component state or props
        // For example:
        resetSections();
        setUserActivityDetails(data);
    }

    const handleUserActivityButtonClick = async (event) => {
        const response = await callApi('/user_activity_summary', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("User activity response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("User activity data: ", data);

        // You can now use the data in your component state or props
        // For example:
        resetSections();
        setUserActivitySummary(data);
    }

    const handleSummaryButtonClick = async (event) => {
        const response = await callApi('/workflow_summary', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Summary response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("Summary data: ", data);

        // You can now use the data in your component state or props
        // For example:
        resetSections();
        setWorkflowSummary(data);
    };

    // Prepare the data for the pie chart
    const pieData = workflowSummary ? [
        {name: 'Total Workflows', value: workflowSummary.total_workflows},
        {name: 'Completed Workflows', value: workflowSummary.completed_workflows},
        {name: 'Total Tasks', value: workflowSummary.total_tasks},
        {name: 'Completed Tasks', value: workflowSummary.completed_tasks},
    ] : [];

    const columns = useMemo(
        () => [
            {
                Header: 'Task ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Task Name',
                accessor: 'task_name', // accessor is the "key" in the data
            },
            {
                Header: 'Start',
                accessor: 'start',
            },
            {
                Header: 'End',
                accessor: 'end',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Start Time',
                accessor: 'start_time',
            },
            {
                Header: 'Time Spent',
                accessor: 'time_spent',
            },
        ],
        []
    );

    const sortByRoleOrgAndEmail = (a, b) => {
        // Sort by role
        if (a.role === null && b.role === null) {
          // If both roles are null, proceed to org sorting
        } else if (a.role === null) return 1;
        else if (b.role === null) return -1;
        else if (a.role !== b.role) {
          return b.role.localeCompare(a.role);
        }
        
        // Sort by org
        const isADemo = a.org_name.startsWith('Demo');
        const isBDemo = b.org_name.startsWith('Demo');
        if (isADemo && !isBDemo) return 1;
        if (!isADemo && isBDemo) return -1;
        if (a.org_name !== b.org_name) {
          return a.org_name.localeCompare(b.org_name);
        }
        
        // If roles and orgs are the same, sort by email
        return a.email.localeCompare(b.email);
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: sortedTasks }, useSortBy);

    return (

        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontSize='12px'
        >
            
            
                <HeaderMenus />
            

            <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>


        <div>
            <Button onClick={handleSummaryButtonClick}>Get Workflow Summary</Button>
            <Button onClick={handleUserActivityButtonClick}>Get User Activity</Button>
            <Button onClick={handleUserDetailsButtonClick}>Get User Activity Details</Button>
            <Button onClick={handleChatDetailsButtonClick}>Get Chat Details</Button>
            <Button onClick={handleChatSummariesButtonClick}>Get Chat Summaries</Button>
            <Button onClick={handleUpdateCodeSummariesButtonClick}>Update Code Summaries</Button>
            <Button onClick={handleJSUpdateCodeSummariesButtonClick}>Update JS Code Summaries</Button>
            <Button onClick={handleViewCodeSummariesButtonClick}>View Code Summaries</Button>
            <Button onClick={() => setShowCreateUser(!showCreateUser)}>Show Create User</Button>
            <Button onClick={handleViewUsersButtonClick}>View Users</Button>

                <Box>
                    <Text>Currently impersonating: {selectedUser?.email}</Text>
                    <Button onClick={handleStopImpersonation}>Stop Impersonating</Button>
                </Box>

                {users.length > 0 && (
                    <Table variant="simple">
                        <Thead>
                        <Tr>
                            <Th>Email</Th>
                            <Th>Role</Th>
                            <Th>Organization</Th>
                            <Th>Action</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        {users.sort(sortByRoleOrgAndEmail).map((user) => (
                            <Tr key={user.id}>
                            <Td>{user.email}</Td>
                            <Td>{user.role || 'N/A'}</Td>
                            <Td>{user.org_name}</Td>
                            <Td>
                                <Button 
                                onClick={() => handleStartImpersonation(user.id)}
                                isDisabled={isImpersonating}
                                >
                                Impersonate
                                </Button>
                            </Td>
                            </Tr>
                        ))}
                        </Tbody>
                    </Table>
                    )}


            {showCreateUser && (
            <CreateUser />
            )}

            {functionSummaries && (
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Function Name</Th>
        <Th>Function Description</Th>
        <Th>File Name</Th>
        <Th>File Path</Th>
      </Tr>
    </Thead>
    <Tbody>
      {functionSummaries.map((summary, index) => (
        <Tr key={index}>
          <Td>{summary.function_name}</Td>
          <Td>{summary.summary}</Td>
          <Td>{summary.file_name}</Td>
          <Td>{summary.file_path}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
)}

            {chatSummaries && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Case ID</Th>
                            <Th>Chat Summary</Th>
                            <Th>Date</Th>
                            <Th>Email</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(chatSummaries)
                            .sort((a, b) => new Date(b[1].created_at) - new Date(a[1].created_at)) // Sort by created_at
                            .map(([chatId, summary], index) => (
                                <Tr 
                                    key={index} 
                                    onClick={() => {
                                        const url = `/ionmform?caseid=${summary.case_id}`;
                                        window.open(url, '_blank');
                                    }}                                >
                                    <Td>{summary.case_id}</Td>
                                    <Td>
                                        <ReactMarkdown>
                                            {summary.chat_summary}
                                        </ReactMarkdown>
                                    </Td>
                                    <Td>{summary.email}</Td>
                                    <Td>{summary.created_at}</Td>
                                </Tr>
                            ))}
                    </Tbody>

                </Table>
            )}


            {chatDetails && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Chat Info</Th>
                            <Th>Messages</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {chatDetails.chat_details
                        .sort((a, b) => new Date(b.chat_created_at) - new Date(a.chat_created_at))
                        .map((row, index) => (
                            <Tr key={index}>
                            <Td>{row.email}
                            <br/>
                                {row.chat_id}
                                <br/>
                                {row.chat_created_at}
                                <br/>
                                {row.chat_additional_data}
                                </Td>
                            <Td>
                                {row.messages.map((message, messageIndex) => (
                                    <p key={messageIndex}>
                                        <strong>{messageIndex % 2 === 0 ? 'User: ' : 'Assistant: '}</strong>
                                        {message}
                                    </p>
                                ))}
                            </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

            {userActivityDetails && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Email</Th>
                            <Th>Created At</Th>
                            <Th>Function Call</Th>
                            <Th>Payload</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userActivityDetails.activity_details
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((row, index) => (
                            <Tr key={index}>
                                <Td>{row.email}</Td>
                                <Td>{row.created_at}</Td>
                                <Td>{row.function_call}</Td>
                                <Td>{row.payload}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

            {userActivitySummary && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Email</Th>
                            <Th>Time Slot</Th>
                            <Th>Activity Count</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {userActivitySummary.activity_counts
                        .sort((a, b) => new Date(b.time_slot) - new Date(a.time_slot))
                        .map((row, index) => (
                            <Tr key={index}>
                            <Td>{row.email}</Td>
                            <Td>{row.time_slot}</Td>
                            <Td>{row.activity_count}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
            {workflowSummary && (
                <>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={pieData}
                            cx={200}
                            cy={200}
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {
                                pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                    </PieChart>
                    <Table {...getTableProps()} variant="simple">
                        <Thead>
                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <Tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                        ))}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </>
            )}
        </div>
        </GridItem>
        </Grid>
        </Box>
    );
}

export default Admin;