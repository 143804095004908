import React from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Grid, GridItem } from '@chakra-ui/react';


const CptEmCodeDisplay = ({ data }) => {
  const { 
    analysis = {}, 
    code = '',
    em_score_contribution = {},
    mdmproblems = [],
    patienttype = '',
    risk = {},
    score = '',
    suggest = {}
  } = data || {};

  console.log('cpt em code display memproblems:', mdmproblems);

  const problemTypes = [
    { id: '1', description: 'Self-limited or minor' },
    { id: '2', description: 'Stable chronic illness' },
    { id: '3', description: 'Acute uncomplicated illness or injury' },
    { id: '4', description: 'Chronic illness with mild exacerbation, progression, or side effects of treatment' },
    { id: '5', description: 'Acute illness with systemic symptoms' },
    { id: '6', description: 'Acute complicated injury' },
    { id: '7', description: 'Chronic illness with severe exacerbation, progression, or side effects of treatment' },
    { id: '8', description: 'Acute or chronic illness or injury that poses a threat to life or bodily function' },
    { id: '9', description: 'Abrupt change in neurologic status' },
  ];

  const getCount = (problemType) => {
    return mdmproblems.filter((problem) => problem.id === problemType).length;
  };

  const problemsContribution = () => {
    if (getCount('8') >= 1 || getCount('9') >= 1) {
      return 'High';
    }
    if (getCount('4') >= 1 || getCount('2') >= 2 || getCount('5') >= 1 || getCount('6') >= 1 || getCount('7') >= 1) {
      return 'Moderate';
    }
    if (getCount('1') >= 2 || getCount('2') >= 1 || getCount('3') >= 1) {
      return 'Low';
    }
    return 'N/A';
  };

  const analysisContribution = () => {
    const cat1Count = analysis.cat1?.count || 0;
    const cat2Count = analysis.cat2?.count || 0;
    const cat3Count = analysis.cat3?.count || 0;

    if ((cat1Count >= 3 && cat2Count >= 1) || (cat3Count >= 1 && cat2Count >= 1) || (cat1Count >= 3 && cat3Count >= 1)) {
      return 'High';
    }
    if (cat1Count >= 3 || cat2Count >= 1 || cat3Count >= 1) {
      return 'Moderate';
    }
    if (cat1Count >= 2 || cat2Count >= 1) {
      return 'Low';
    }
    return 'N/A';
  };

  const riskContribution = () => {
    const moderateRisk = parseInt(risk.moderate?.['likelihood score'] || '0');
    const highRisk = parseInt(risk.high?.['likelihood score'] || '0');

    if (highRisk >= 50) {
      return 'High';
    }
    if (moderateRisk >= 50) {
      return 'Moderate';
    }
    return 'Low';
  };

  // New helper functions
  const renderRisk = (risk) => {
    const moderateRisk = risk.moderate['reader discussion'] ?? "N/A";
    const moderateRiskScore = risk.moderate['likelihood score'] ?? "N/A";
    const highRisk = risk.high['reader discussion'] ?? "N/A";
    const highRiskScore = risk.high['likelihood score'] ?? "N/A";

    return (
      <div key="RISK">
        <div>
          Moderate risk discussion:
          {moderateRisk}
          (likelihood score: {moderateRiskScore})
        </div>
        <br />
        <div>
          High risk discussion:
          {highRisk}
          (likelihood score: {highRiskScore})
        </div>
      </div>
    );
  };

  const renderAnalysis = (analysis) => {
    const emAnalysisCatCountArray = [];
    const emAnalysisCatArray = [];

    for (let catKey in analysis) {
      let category = analysis[catKey];

      if (Object.keys(category).length > 0) {
        let count = category.count ?? "N/A";
        let items = category.items ?? "N/A";
        let lastChar = catKey.charAt(catKey.length - 1);
        emAnalysisCatCountArray[lastChar] = `Category ${lastChar} (${count} items)`;
        items = items.split("\n");
        emAnalysisCatArray[lastChar] = items.map((item, itemKey) => (
          <div key={itemKey}>
            {item}<br />
          </div>
        ));
      }
    }

    return emAnalysisCatCountArray.map((catCount, index) => (
      <div key={index}>
        {catCount}
        <div className="indented2">
          {emAnalysisCatArray[index]}
        </div>
      </div>
    ));
  };

  const renderMdmProblems = (mdmProblems) => {
    return mdmProblems ? (
      <div>
        {mdmProblems.map((problem) => (
          <div key={problem.id}>
            <strong>Problem Type:</strong> {problem.id}
            <div className="indented2">
              <div>
                <strong>Description:</strong> {problem.description}
              </div>
              <div>
                <strong>Problem:</strong> {problem.problem}
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : null;
  };



  return (
    <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(1, 1fr)" gap={2}>
      <GridItem bg="white" fontSize="12" borderRadius={3} boxShadow="md" p={2}>
        <Text fontSize="sm" fontWeight="normal" align="left">CPT E/M: {code}</Text>
        <Text fontSize="sm" fontWeight="normal" align="left">Patient Type: {patienttype}</Text>
        <Text fontSize="sm" fontWeight="normal" align="left">Overall Score: {score}</Text>

        <Table size="sm" mt={4}>
          <Tbody>
            <Tr>
              <Td>Problems Addressed</Td>
              <Td>{em_score_contribution.problems || 'N/A'}</Td>
            </Tr>
            <Tr>
              <Td>Data Reviewed and Analyzed</Td>
              <Td>{em_score_contribution.analysis || 'N/A'}</Td>
            </Tr>
            <Tr>
              <Td>Risk of Complications/Morbidity</Td>
              <Td>{em_score_contribution.risk || 'N/A'}</Td>
            </Tr>
          </Tbody>
        </Table>

        <Table size="sm" mt={4}>
          <Thead>
            <Tr>
              <Th>Problem</Th>
              <Th>Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            {problemTypes
              .filter((problemType) => getCount(problemType.id) > 0)
              .map((problemType) => (
                <Tr key={problemType.id}>
                  <Td>{problemType.id}</Td>
                  <Td>{getCount(problemType.id)}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Text fontSize="sm" mt={2}>
          Moderate: two or more problem types 2, or one or more problem types 4, 5, 6, 7
          High: one or more problem types 8, 9
        </Text>

        <Table size="sm" mt={4}>
          <Thead>
            <Tr>
              <Th>Analysis</Th>
              <Th>Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(analysis).map(([key, value]) => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{value.count}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Text fontSize="sm" mt={2}>
          Analysis Contribution
          Moderate: requires one of: three Category 1, one Category 2, one Category 3
          High: requires two of: three Category 1, one Category 2, one Category 3
        </Text>

        <Table size="sm" mt={4}>
          <Thead>
            <Tr>
              <Th>Risk</Th>
              <Th>Likelihood Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(risk).map(([key, value]) => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{value['likelihood score']}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Box mt={4}>
          <Text fontSize="sm" fontWeight="bold">Detailed MDM Problems</Text>
          {renderMdmProblems(mdmproblems)}
        </Box>


        <Box mt={4}>
          <Text fontSize="sm" fontWeight="bold">Risk Discussion</Text>
          {Object.entries(risk).map(([key, value]) => (
            <Box key={key} mt={2}>
              <Text fontSize="sm" fontWeight="semibold">{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
              <Text fontSize="sm" whiteSpace="pre-wrap">{value['reader discussion']}</Text>
            </Box>
          ))}
        </Box>

        {false && (
        <Box mt={4}>
          <Text fontSize="sm" fontWeight="bold">Suggestions</Text>
          {Object.entries(suggest).map(([key, value]) => (
            <Box key={key} mt={2}>
              <Text fontSize="sm" fontWeight="semibold">{key}</Text>
              <Text fontSize="sm" whiteSpace="pre-wrap">{value}</Text>
            </Box>
          ))}
        </Box>
        )}







        {/* New sections */}
        <Box mt={4}>
          <Text fontSize="sm" fontWeight="bold">Alternative Risk Rendering</Text>
          {renderRisk(risk)}
        </Box>

        <Box mt={4}>
          <Text fontSize="sm" fontWeight="bold">Alternative Analysis Rendering</Text>
          {renderAnalysis(analysis)}
        </Box>









      </GridItem>
    </Grid>
  );
};

export default CptEmCodeDisplay;