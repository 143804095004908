import React, { useState, useContext, useEffect } from 'react';
import {
  useToast, Box, Text, Button, Grid, GridItem, VStack, HStack, Select, Input, FormControl, FormLabel,
  Textarea, Switch, NumberInput, NumberInputField, Icon
} from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import { FiFile, FiUpload, FiEye } from 'react-icons/fi';

function CaseCreationComponent() {
  const [caseTypes, setCaseTypes] = useState([]);
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [caseTypeDetails, setCaseTypeDetails] = useState(null);
  const [externalCaseId, setExternalCaseId] = useState('');
  const [formData, setFormData] = useState({});
  const [processing, setProcessing] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  const [encounterId, setEncounterId] = useState(null);

  useEffect(() => {
    fetchCaseTypes();
  }, []);

  useEffect(() => {
    if (selectedCaseType) {
      fetchCaseTypeDetails(selectedCaseType);
    }
  }, [selectedCaseType]);

  const fetchCaseTypes = async () => {
    try {
      const response = await callApi('/api/case_types', accessToken);
      const data = await response.json();
      if (response.ok) {
        setCaseTypes(data.case_types);
      } else {
        throw new Error(data.error || 'Failed to fetch case types');
      }
    } catch (error) {
      console.error('Error fetching case types:', error);
      toast({
        title: 'Failed to fetch case types',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCaseTypeDetails = async (caseTypeId) => {
    try {
      const response = await callApi(`/api/case_type/${caseTypeId}`, accessToken);
      const data = await response.json();
      if (response.ok) {
        setCaseTypeDetails(data.data_structure);
        initializeFormData(data.data_structure.content_slots);
      } else {
        throw new Error(data.error || 'Failed to fetch case type details');
      }
    } catch (error) {
      console.error('Error fetching case type details:', error);
      toast({
        title: 'Failed to fetch case type details',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const initializeFormData = (contentSlots) => {
    const initialData = {};
    contentSlots.forEach(slot => {
      if (slot.input_type === 'form') {
        initialData[`content_${slot.slot_number}`] = {};
        slot.form_fields.forEach(field => {
          initialData[`content_${slot.slot_number}`][field.name] = field.default || '';
        });
      } else if (slot.input_type === 'file-or-text') {
        initialData[`content_${slot.slot_number}`] = slot.default || '';
      } else {
        initialData[`content_${slot.slot_number}`] = '';
      }
      initialData[`file_${slot.slot_number}`] = null;
    });
    setFormData(initialData);
  };


  const handleInputChange = (slotNumber, value) => {
    setFormData(prev => ({
      ...prev,
      [`content_${slotNumber}`]: value
    }));
  };

  const handleFileChange = (slotNumber, file) => {
    setFormData(prev => ({
      ...prev,
      [`file_${slotNumber}`]: file
    }));
  };



  const handleFormInputChange = (slotNumber, fieldName, value) => {
    setFormData(prev => ({
      ...prev,
      [`content_${slotNumber}`]: {
        ...prev[`content_${slotNumber}`],
        [fieldName]: value
      }
    }));
  };



  const handleCreateCase = async () => {
    if (!externalCaseId || !selectedCaseType) {
      toast({
        title: 'Missing information',
        description: "Please enter an External Case ID and select a Case Type.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    setProcessing(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('external_case_id', externalCaseId);
      formDataToSend.append('case_type_id', selectedCaseType);
      formDataToSend.append('code_case', 'true'); // Add this line to include the code_case flag
  
      // Process each content slot based on the case type details
      caseTypeDetails.content_slots.forEach(slot => {
        const slotNumber = slot.slot_number;
        const contentKey = `content_${slotNumber}`;
        const fileKey = `file_${slotNumber}`;
  
        if (formData[fileKey] instanceof File) {
          // If it's a file, use it directly with its original name
          formDataToSend.append(`file_${slotNumber}`, formData[fileKey], formData[fileKey].name);
        } else if (formData[contentKey]) {
          // If it's text content, convert it to a blob
          const contentType = slot.input_type === 'form' 
            ? 'application/json' 
            : 'text/plain';
          
          let content = formData[contentKey];
          if (contentType === 'application/json') {
            // If it's form data, parse it and stringify it properly
            try {
              const parsedContent = JSON.parse(content);
              content = JSON.stringify(parsedContent);
            } catch (e) {
              console.error('Error parsing form data:', e);
              content = JSON.stringify({ rawContent: content });
            }
          }
          
          const blob = new Blob([content], { type: contentType });
          formDataToSend.append(`file_${slotNumber}`, blob, `content_${slotNumber}.${contentType === 'application/json' ? 'json' : 'txt'}`);
        }
        // No else block here, so empty slots are not sent
      });
  
      const response = await callApi('/create_complete_external_case', accessToken, {
        method: 'POST',
        body: formDataToSend,
      });
  
      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case created and coded successfully',
          description: `Case ID: ${data.case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setEncounterId(data.encounter_id); // Store the encounter ID
        console.log('Case created:', data);
        // Reset form
        setExternalCaseId('');
        setSelectedCaseType('');
        setFormData({});
      } else {
        throw new Error(data.error || 'Failed to create case');
      }
    } catch (error) {
      console.error('Error creating case:', error);
      toast({
        title: 'Case creation failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };


  const handleViewCodes = () => {
    window.open(`/medical-necessity-general?case_id=${encounterId}`, '_blank', 'noopener,noreferrer');
  };
  
  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontSize="lg" fontWeight="bold">Create New Case</Text>
                <HStack>
                  <Input
                    placeholder="Enter External Case ID"
                    value={externalCaseId}
                    onChange={(e) => setExternalCaseId(e.target.value)}
                  />
                  <Select
                    placeholder="Select Case Type"
                    value={selectedCaseType}
                    onChange={(e) => setSelectedCaseType(e.target.value)}
                  >
                    {caseTypes.map(type => (
                      <option key={type.id} value={type.id}>{type.case_type}</option>
                    ))}
                  </Select>
                </HStack>
              </Box>

              {caseTypeDetails && (
                <Box>
                  <Text fontSize="md" fontWeight="bold">{caseTypeDetails.description}</Text>
                  {caseTypeDetails.content_slots.map(slot => (
                    <Box key={slot.slot_number} mt={4} p={4} borderWidth={1} borderRadius="md">
                      <Text fontWeight="bold">{slot.name}</Text>
                      <Text fontSize="sm">{slot.instructions}</Text>



                      {slot.input_type === 'form' && (
                        <VStack align="stretch" spacing={4} mt={2}>
                            {slot.form_fields.map((field, index) => (
                            <FormControl key={index}>
                                <FormLabel>{field.label}</FormLabel>
                                {field.type === 'text' && (
                                <Input
                                    type="text"
                                    value={formData[`content_${slot.slot_number}`]?.[field.name] || field.default || ''}
                                    onChange={(e) => handleFormInputChange(slot.slot_number, field.name, e.target.value)}
                                />
                                )}
                                {field.type === 'number' && (
                                <NumberInput
                                    value={formData[`content_${slot.slot_number}`]?.[field.name] || field.default || ''}
                                    onChange={(value) => handleFormInputChange(slot.slot_number, field.name, value)}
                                >
                                    <NumberInputField />
                                </NumberInput>
                                )}
                                {field.type === 'switch' && (
                                <Switch
                                    isChecked={formData[`content_${slot.slot_number}`]?.[field.name] || field.default === 'true'}
                                    onChange={(e) => handleFormInputChange(slot.slot_number, field.name, e.target.checked)}
                                />
                                )}
                            </FormControl>
                            ))}
                        </VStack>
                        )}


                        {(slot.input_type === 'file-upload' || slot.input_type === 'file-or-text') && (
                        <VStack mt={2}>
                            <Input
                            type="file"
                            onChange={(e) => handleFileChange(slot.slot_number, e.target.files[0])}
                            />
                            {slot.input_type === 'file-or-text' && (
                            <Textarea
                                placeholder="Or enter text content"
                                value={formData[`content_${slot.slot_number}`] || ''}
                                onChange={(e) => handleInputChange(slot.slot_number, e.target.value)}
                            />
                            )}
                        </VStack>
                        )}


                    </Box>
                  ))}
                </Box>
              )}

            <Button
              onClick={handleCreateCase}
              isLoading={processing}
              loadingText="Creating Case"
              colorScheme="blue"
              isDisabled={!externalCaseId || !selectedCaseType}
            >
              Create Case
            </Button>
            {encounterId && (
              <Button
                onClick={handleViewCodes}
                leftIcon={<Icon as={FiEye} />}
                colorScheme="green"
              >
                View Codes
              </Button>
            )}
            </VStack>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default CaseCreationComponent;