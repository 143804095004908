//Components/ContentLookup.js

import React, { useState, useContext } from 'react';
import {
  ChakraProvider, Box, VStack, Heading, Select, Input, Button,
  useToast, Accordion, AccordionItem, AccordionButton,
  AccordionPanel, AccordionIcon, Text, Flex, Grid, GridItem, Image
} from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import ReactMarkdown from 'react-markdown';
import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function ContentLookup() {
  const [searchType, setSearchType] = useState('cpt');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchValue = e.target.elements.searchInput.value;

    if (!searchValue.trim()) {
      toast({
        title: 'Error',
        description: 'Please enter a search value',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setSearchResults(null);

    try {
      let endpoint;
      switch (searchType) {
        case 'cpt':
          endpoint = `/cpt_lookup?cpt_code=${searchValue}`;
          break;
        case 'article':
          endpoint = `/search_by_article_id?article_id=${searchValue}`;
          break;
        case 'lcd':
          endpoint = `/search_by_lcd_id?lcd_id=${searchValue}`;
          break;
        case 'context':
          endpoint = `/search_by_context?context=${searchValue}`;
          break;
      }

      const response = await callApi(endpoint, accessToken, { method: 'GET' });
      const data = await response.json();

      if (response.ok) {
        setSearchResults(data);
        toast({
          title: 'Search successful',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(data.error || 'An error occurred while fetching data');
      }
    } catch (error) {
      console.error('Error during search:', error);
      toast({
        title: 'Search failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSearchForm = () => (
    <form onSubmit={handleSubmit}>
      <Flex>
        <Select 
          value={searchType} 
          onChange={(e) => setSearchType(e.target.value)} 
          mr={2}
        >
          <option value="cpt">CPT Code</option>
          <option value="article">Article ID</option>
          <option value="lcd">LCD ID</option>
          <option value="context">Context</option>
        </Select>
        <Input
          name="searchInput"
          placeholder={`Enter ${searchType.toUpperCase()}`}
          mr={2}
        />
        <Button type="submit" isLoading={isLoading}>
          Search
        </Button>
      </Flex>
    </form>
  );

  const renderSearchResults = () => {
    if (!searchResults) return null;

    const ResultAccordion = ({ title, children }) => (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Heading size="md">{title}</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    );

    return (
      <Accordion allowMultiple>
        <ResultAccordion title="Basic CPT Data">
          <Text><strong>CPT Code:</strong> {searchResults.cpt_code}</Text>
          <Text><strong>Concept ID:</strong> {searchResults.concept_id}</Text>
          <Text><strong>Clinician Description:</strong> {searchResults.clinician_description}</Text>
          <Text><strong>Patient Description:</strong> {searchResults.patient_description}</Text>
        </ResultAccordion>

        {searchResults.ama_cpt_data && (
          <ResultAccordion title="AMA CPT Data">
            <Text><strong>Long Description:</strong> {searchResults.ama_cpt_data.long_description}</Text>
            <Text><strong>Valid From:</strong> {new Date(searchResults.ama_cpt_data.valid_from).toLocaleDateString()}</Text>
            <Text><strong>Valid To:</strong> {searchResults.ama_cpt_data.valid_to ? new Date(searchResults.ama_cpt_data.valid_to).toLocaleDateString() : 'Present'}</Text>
          </ResultAccordion>
        )}

        {searchResults.mcd_article_data && (
          <ResultAccordion title="MCD Article Data">
            <Text><strong>Number of related MCD articles:</strong> {searchResults.mcd_article_data.count}</Text>
            {searchResults.mcd_article_data.sample_articles && searchResults.mcd_article_data.sample_articles.length > 0 && (
              <Box mt={2}>
                <Text fontWeight="bold">Sample MCD Articles:</Text>
                {searchResults.mcd_article_data.sample_articles.map((article, index) => (
                  <Box key={index} mt={2} pl={4}>
                    <Text><strong>Article ID:</strong> {article.article_id}</Text>
                    <Text><strong>Article Version:</strong> {article.article_version}</Text>
                    <Text><strong>Display ID:</strong> {article.display_id}</Text>
                    <Text><strong>Long Description:</strong> {article.long_description}</Text>
                    <Text><strong>Short Description:</strong> {article.short_description}</Text>
                    <Text><strong>Publication Date:</strong> {article.publication_date ? new Date(article.publication_date).toLocaleDateString() : 'N/A'}</Text>
                    <Text><strong>Effective Date:</strong> {article.effective_date ? new Date(article.effective_date).toLocaleDateString() : 'N/A'}</Text>
                    <Text><strong>End Date:</strong> {article.end_date ? new Date(article.end_date).toLocaleDateString() : 'N/A'}</Text>
                    <Text><strong>Status:</strong> {article.status}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </ResultAccordion>
        )}

        {searchResults.lcd_data && searchResults.lcd_data.length > 0 && (
          <ResultAccordion title="LCD Data">
            {searchResults.lcd_data.map((lcd, index) => (
              <Box key={index} mt={2}>
                <Text><strong>LCD ID:</strong> {lcd.lcd_id}</Text>
                <Text><strong>LCD Version:</strong> {lcd.lcd_version}</Text>
                <Text><strong>Long Description:</strong> {lcd.long_description}</Text>
                <Text><strong>Short Description:</strong> {lcd.short_description}</Text>
              </Box>
            ))}
          </ResultAccordion>
        )}

        {searchResults.rev_lcd_data && searchResults.rev_lcd_data.length > 0 && (
          <ResultAccordion title="Revedy LCD Data">
            {searchResults.rev_lcd_data.map((revLcd, index) => (
              <Box key={index} mt={2}>
                <Text><strong>LCD ID:</strong> {revLcd.lcd_id}</Text>
                <Text><strong>Relevancy Score:</strong> {revLcd.relevancy_score}</Text>
                <Text><strong>Relevant:</strong> {revLcd.relevant}</Text>
                <Text><strong>Relevancy Source:</strong> {JSON.stringify(revLcd.relevancy_source)}</Text>
              </Box>
            ))}
          </ResultAccordion>
        )}

        {searchResults.icd10_data && searchResults.icd10_data.length > 0 && (
          <ResultAccordion title="ICD-10 Data">
            {searchResults.icd10_data.map((icd10, index) => (
              <Box key={index} mt={2}>
                <Text><strong>ICD-10 Code:</strong> {icd10.icd10_code}</Text>
                <Text><strong>Relevancy Score:</strong> {icd10.relevancy_score}</Text>
                <Text><strong>Relevancy Source:</strong> {JSON.stringify(icd10.relevancy_source)}</Text>
              </Box>
            ))}
          </ResultAccordion>
        )}

        {searchResults.hcpcs_data && (
          <ResultAccordion title="HCPCS Data">
            <Text><strong>Long Description:</strong> {searchResults.hcpcs_data.long_description}</Text>
            <Text><strong>Short Description:</strong> {searchResults.hcpcs_data.short_description}</Text>
            <Text><strong>Pricing Indicator Code:</strong> {searchResults.hcpcs_data.pricing_indicator_code}</Text>
            <Text><strong>Multiple Pricing Indicator Code:</strong> {searchResults.hcpcs_data.multiple_pricing_indicator_code}</Text>
            <Text><strong>Coverage Code:</strong> {searchResults.hcpcs_data.coverage_code}</Text>
            <Text><strong>Berenson-Eggers Type of Service Code:</strong> {searchResults.hcpcs_data.berenson_eggers_type_of_service_code}</Text>
            <Text><strong>Type of Service Code:</strong> {searchResults.hcpcs_data.type_of_service_code}</Text>
            <Text><strong>Anesthesia Base Unit Quantity:</strong> {searchResults.hcpcs_data.anesthesia_base_unit_quantity}</Text>
            <Text><strong>Add Date:</strong> {searchResults.hcpcs_data.add_date}</Text>
            <Text><strong>Action Effective Date:</strong> {searchResults.hcpcs_data.action_effective_date}</Text>
            <Text><strong>Termination Date:</strong> {searchResults.hcpcs_data.termination_date}</Text>
            <Text><strong>Action Code:</strong> {searchResults.hcpcs_data.action_code}</Text>
          </ResultAccordion>
        )}

        {searchResults.cms_source_data && searchResults.cms_source_data.length > 0 && (
          <ResultAccordion title="CMS Source Web Content">
            <Accordion allowMultiple>
              {Object.entries(searchResults.cms_source_data.reduce((acc, item) => {
                item.matching_groups.forEach(group => {
                  if (!acc[group]) acc[group] = [];
                  acc[group].push(item);
                });
                return acc;
              }, {})).map(([group, items], groupIndex) => (
                <AccordionItem key={groupIndex}>
                  <h3>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="bold">Matching Group: {group}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h3>
                  <AccordionPanel pb={4}>
                    {items.map((item, itemIndex) => (
                      <Box key={itemIndex} mt={4} borderWidth="1px" borderRadius="lg" p={4}>
                        <Text><strong>URL:</strong> <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a></Text>
                        <Accordion allowMultiple mt={2}>
                          <AccordionItem>
                            <h4>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  <Text fontWeight="bold">Extracted Content</Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h4>
                            <AccordionPanel pb={4}>
                              <Text>{item.extracted_content}</Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <h4>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  <Text fontWeight="bold">Extracted Markdown</Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h4>
                            <AccordionPanel pb={4}>
                              <ReactMarkdown>{item.extracted_markdown}</ReactMarkdown>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </ResultAccordion>
        )}

        {searchResults.analysis_result && searchResults.analysis_result.length > 0 && (
          <ResultAccordion title="Analysis Results">
            {searchResults.analysis_result.map((analysis, index) => (
              <Box key={index} mt={2} borderWidth="1px" borderRadius="lg" p={4}>
                <Text><strong>Context:</strong> {analysis.context}</Text>
                <Text><strong>Source Type:</strong> {analysis.source_type}</Text>
                <Text><strong>Source ID:</strong> {analysis.source_id}</Text>
                <Accordion allowMultiple mt={2}>
                  <AccordionItem>
                    <h4>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight="bold">Analysis</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel pb={4}>
                      <Text><strong>Summary:</strong></Text>
                      <ReactMarkdown>{analysis.analysis.summary}</ReactMarkdown>
                      <Text mt={2}><strong>Relevancy:</strong></Text>
                      <Text>Score: {analysis.analysis.relevancy.score}</Text>
                      <ReactMarkdown>{analysis.analysis.relevancy.explanation}</ReactMarkdown>
                      <Text mt={2}><strong>Pseudo Code:</strong></Text>
                      <ReactMarkdown>{analysis.analysis.pseudo_code}</ReactMarkdown>
                      <Text mt={2}><strong>Relevant Text:</strong></Text>
                      <ReactMarkdown>{analysis.analysis.relevant_text}</ReactMarkdown>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Text><strong>Created At:</strong> {analysis.created_at ? new Date(analysis.created_at).toLocaleString() : 'N/A'}</Text>
              </Box>
            ))}
          </ResultAccordion>
        )}

        {searchResults.related_content && searchResults.related_content.length > 0 && (
          <ResultAccordion title="Related Content">
            {searchResults.related_content.map((content, index) => (
              <Box key={index} mt={2} borderWidth="1px" borderRadius="lg" p={4}>
                <Text><strong>Content Type:</strong> {content.content_type}</Text>
                <Text><strong>Content ID:</strong> {content.content_id}</Text>
                <Text><strong>Match Type:</strong> {content.match_type}</Text>
                <Text><strong>Matches:</strong> {content.matches}</Text>
                <Text><strong>Created At:</strong> {content.created_at ? new Date(content.created_at).toLocaleString() : 'N/A'}</Text>
                <Text><strong>Valid From:</strong> {content.valid_from ? new Date(content.valid_from).toLocaleString() : 'N/A'}</Text>
                <Text><strong>Valid To:</strong> {content.valid_to ? new Date(content.valid_to).toLocaleString() : 'N/A'}</Text>
              </Box>
            ))}
          </ResultAccordion>
        )}
      </Accordion>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Grid
        templateAreas={`"header" "main" "footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100vh'
        gap='1'
        color='blackAlpha.800'
      >
        <GridItem area={'header'}>
          {/* Add your HeaderMenus component here */}
        </GridItem>

        <GridItem bg='gray.50' area={'main'} overflowY="auto">
          <Box p={4}>
            <VStack spacing={4} align="stretch">
              <Heading mb={4}>Content Lookup</Heading>
              {renderSearchForm()}
              {renderSearchResults()}
            </VStack>
          </Box>
        </GridItem>

        <GridItem bg='white' borderTop='1px' borderColor='brand.200' area={'footer'}>
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
            <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
              &copy; {new Date().getFullYear()}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </ChakraProvider>
  );
}


export default ContentLookup;