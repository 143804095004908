// APIDocs.js
import { Box, Grid, GridItem, Text, VStack, HStack, Link, Divider, Heading, Code, Container,Button, Spinner } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus'; // Adjust the import path based on your project structure
import React, { useState, useContext } from 'react';
import { AppContext } from '../components/AppContext'; // Adjust the import path based on your project structure
import { callApi } from '../callApi'; 
import {  UnorderedList, ListItem } from "@chakra-ui/react";

function APIDocs() {
    const { accessToken } = useContext(AppContext);
    const [credentials, setCredentials] = useState(null);
    const [loadingCredentials, setLoadingCredentials] = useState(false);
    const [version, setVersion] = useState([]);
    const [loadingVersion, setLoadingVersion] = useState(false);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };


  const handleGetVersionClick = async () => {
    setLoadingVersion(true);
    try {
      const response = await callApi('/version', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setVersion(data.version);
    } catch (error) {
      console.error("Error fetching credentials: ", error);
      // Handle errors as appropriate
    }
    setLoadingVersion(false);
  };



  const handleGetCredentialsClick = async () => {
    setLoadingCredentials(true);
    try {
      const response = await callApi('/getcredentials', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setCredentials(data);
    } catch (error) {
      console.error("Error fetching credentials: ", error);
      // Handle errors as appropriate
    }
    setLoadingCredentials(false);
  };

  return (
    <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'350px 4fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
                {/* Header with menu */}
                    <HeaderMenus />

        {/* Navigation sidebar */}
        <GridItem area="main" bg='white' p={3}>
  <br/>
  
  <VStack textAlign="left" align="start" spacing={3}>
    <Link fontSize="lg" fontWeight="normal" target="_blank" href="/logviewer" 
      color="blue.500">
      View Logs
    </Link>

    {/* Header text saying Revedy API documentation */}
    <Heading as="h3" size="m">Revedy API Documentation</Heading>
    <Divider />
    <Link fontSize="sm" fontWeight="normal" href="#overview">Overview</Link>
    <Link fontSize="sm" fontWeight="normal" href="#authentication">Authentication</Link>
    <Link fontSize="sm" fontWeight="normal" href="#endpoints">Endpoints</Link>
    <VStack textAlign="left" align="start" spacing={1} pl={4}>
      <Link fontSize="sm" fontWeight="normal" href="#encounter-analysis">Encounter Analysis</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={8}>

      <Link fontSize="sm" fontWeight="normal" href="/ea" isExternal>
        Try E/M Encounter Coding API <ExternalLinkIcon mx="2px" />
      </Link>
      <Link fontSize={"sm"} fontWeight="normal" href="#beta-encounter-analysis-cognitive">Encounter Analysis Cognitive (beta)</Link>
      </VStack>
      <Link fontSize="sm" fontWeight="normal" href="#encounter-manage">Encounter Manage</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={8}>
        <Link fontSize="sm" fontWeight="normal" href="#encounters">Encounters</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounters_search">Search Encounters</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounter">Encounter</Link>
      <Link fontSize="sm" fontWeight="normal" href="/el" isExternal>
        Try it<ExternalLinkIcon mx="2px" />
      </Link>
      </VStack>

      <Link fontSize="sm" fontWeight="normal" href="#notes-manage">Notes Manage</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={8}>
        <Link fontSize="sm" fontWeight="normal" href="#save-note">Save Note</Link>
        <Link fontSize="sm" fontWeight="normal" href="#get-recent-note">Get Recent Note</Link>
        <Link fontSize="sm" fontWeight="normal" href="#get-all-notes">Get All Notes</Link>

      <Link fontSize="sm" fontWeight="normal" href="/el" isExternal>
        Try it<ExternalLinkIcon mx="2px" />
      </Link>
      </VStack>      
      <Link fontSize="sm" fontWeight="normal" href="#codes-manage">Codes Manage</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={8}>
        <Link fontSize="sm" fontWeight="normal" href="#current-encounter-codes">Get Current Encounter Codes</Link>
        <Link fontSize="sm" fontWeight="normal" href="#manage-encounter-code-status">Manage Encounter Code Status</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounter-code-history">Get Encounter Code History</Link>
      
      <Link fontSize="sm" fontWeight="normal" href="/el" isExternal>
        Try it <ExternalLinkIcon mx="2px" />
      </Link>
      </VStack>
      <Link fontSize="sm" fontWeight="normal" href="#patient-content-manage">Patient Content Manage</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={8}>
        <Link fontSize="sm" fontWeight="normal" href="#generate-patient-facing-content">Generate Patient Facing Content</Link>
        <Link fontSize="sm" fontWeight="normal" href="#get-recent-patient-facing-content">Get Recent Patient Facing Content</Link>
        <Link fontSize="sm" fontWeight="normal" href="#get-all-patient-facing-content">Get All Patient Facing Content</Link>
    
      <Link fontSize="sm" fontWeight="normal" href="/el" isExternal>
        Try it <ExternalLinkIcon mx="2px" />
      </Link>
      </VStack></VStack>

      <Link fontSize="sm" fontWeight="normal" href="#ionm-coding">IONM Coding</Link>
        <VStack textAlign="left" align="start" spacing={1} pl={8}>
        <Link fontSize="sm" fontWeight="normal" href="#process-complete-ionm-case">Process Complete IONM Case</Link>
          <Link fontSize="sm" fontWeight="normal" href="#create-external-case">Create External Case</Link>
          <Link fontSize="sm" fontWeight="normal" href="#upload-file">Upload File</Link>
          <Link fontSize="sm" fontWeight="normal" href="#list-external-cases">List External Cases</Link>
          <Link fontSize="sm" fontWeight="normal" href="#process-ionm-case">Process IONM Case</Link>
        </VStack>

    <VStack textAlign="left" align="start" spacing={1} pl={4}>
    </VStack>
    <Link fontSize="sm" fontWeight="normal" href="#error-codes">Error Codes</Link>
    <Link fontSize="sm" fontWeight="normal" href="#rate-limits">Rate Limits</Link>
  </VStack>
  
  </GridItem>


        <GridItem area="results" bg='white' p={6} ml="30px" overflowY="auto" height="700px" width="1000px">
            <VStack spacing={8} textAlign="left" align="start">
            <Box id="overview">
                <Heading as="h2" size="lg" mb={2}>Overview</Heading>
                <Text fontSize="md" >
                    The Revedy API provides a secure way for partners to programmatically access our services. Authentication is handled through a standard OAuth 2.0 process, where clients are issued tokens that grant temporary access to the API.
                </Text>
                </Box>
                <Divider />

                <Box id="authentication">
                    <Heading as="h2" size="lg" mb={2}>Authentication</Heading>
                    <Text fontSize="md" >
                    To access the API, clients must first obtain an access token by providing their unique client credentials. This token is then used in the Authorization header as a Bearer token to authenticate API requests.
                </Text>
                <Text fontSize="md" >
                    All interactions with the API should be conducted over HTTPS to ensure the confidentiality and integrity of the data transmitted.
                </Text>
                <Text fontSize="md" >
                    The base URL for all API endpoints is <Code>https://api.revedy.io</Code>. Below is an example of how to obtain an access token and make a request to the API.
                </Text>
                <Text fontSize="md">
                    Example request to obtain an access token:
                </Text>
                <Code p={2} bg="gray.100"  whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://api.revedy.io/oauth/token' \\
--header 'content-type: application/json' \\
--data '{
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "audience": "https://revedy.io/demo",
    "grant_type": "client_credentials"
}'`} />
<Text fontSize="md" mt={4}>
                    Example request to the <Code>/getrecentnotes</Code> endpoint using the access token:
                </Text>
                <Code whiteSpace="pre" p={2} bg="gray.100" children=
{`curl --request GET \\
--url 'https://api.revedy.io/getrecentnotes' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN'`} />
</Box>
                <Divider />
                <Box id="credentials">
                    <Heading as="h2" size="lg" mb={2}>Client Credentials</Heading>
                    <Button onClick={handleGetCredentialsClick} isLoading={loadingCredentials}>
                        Get Credentials
                    </Button>
                    {credentials && (
                        <>
                        <Text>Your Client ID: {credentials.clientId}</Text>
                        <Text>Your Client Secret: {credentials.clientSecret}</Text>
                        </>
                    )}
                </Box>
                <Divider />

                <Box id="endpoints">
              <Heading as="h2" size="lg" mb={2}>Endpoints</Heading>
            </Box>




            <Box id="encounter-analysis">
    <Heading as="h3" size="md" mb={2}>Encounter Analysis</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounter-analysis</Code> endpoint is designed to process various types of data (audio, transcript, or external note) 
      and perform analysis based on the specified analysis types. 
      The process involves creating an encounter record, processing the source data, and performing the requested analysis (e.g., coding).
    </Text>

    <Link
    fontSize="md"
    href="/ea"
    target="_blank"
    rel="noopener noreferrer"
  >
    Try it out
  </Link>

    <Text fontSize="md">
      HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounter-analysis</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: multipart/form-data`} />
    <br /><br />
    <Text fontSize="md">
      Form Parameters:<br />
      One of audio, transcript, or external_note is required<br />
      All other fields are optional. If no encounter_id is provided, a unique identifier will be generated.
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {
`encounter_id: Unique identifier for the encounter in your system.
external_patient_id: External identifier for the patient.
external_provider_id: External identifier for the provider.
fhir_patient_reference: FHIR reference for the patient.
fhir_practitioner_reference: FHIR reference for the practitioner.
fhir_location_reference: FHIR reference for the location.
analysis_type: The type(s) of analysis to be performed (e.g., "coding").
note_template_id: Identifier for the note template to be used.
note_guidelines_id: Identifier for the note guidelines to be followed.
encounter_type_id: Identifier for the encounter type.
audio: The audio file to be processed.
external_note: The external note to be processed. 
transcript: The transcript to be processed.
additional_data: JSON array containing additional data points.`}
    </Code>

    <br /><br />
    <Text fontSize="md">
      Additional Data Parameters:<br />
      Additional data is optional.
      It allows you to store additional patient and encounter data of any type. 
      This data will be returned with any Encounter request.
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
    {`{
  "data_key": "string", // Required - Unique identifier for the data point
  "data_value": "string", // Required - Value of the data point
  "data_type": "string", // Required - Data type of the value (e.g., string, integer, date)
  "fhir_resource_type": "string", // Optional - FHIR resource type associated with the data point
  "fhir_resource_id": "string", // Optional - Unique identifier for the FHIR resource
  "fhir_path": "string", // Optional - FHIR path to locate the relevant information within the resource
  "fhir_display": "string", // Optional - Human-readable display text for the data point
  "fhir_extension_url": "string", // Optional - URL for a FHIR extension providing additional information
  "fhir_extension_value": "string" // Optional - Value for the FHIR extension
  "fhir_version": "string" // Optional - FHIR version (e.g., 4.0.1)
}`}
    </Code>

    <Text fontSize="md">
      Sample Request:
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer TOKENHERE' \\
--header 'Content-Type: multipart/form-data' \\
--form 'encounter_id=ZZZ447652' \\
--form 'external_patient_id=ZZZ456' \\
--form 'external_provider_id=ZZZ789' \\
--form 'fhir_patient_reference=Patient/ZZZ123' \\
--form 'fhir_practitioner_reference=Practitioner/ZZZ456' \\
--form 'fhir_location_reference=Location/ZZZ789' \\
--form 'analysis_type=coding' \\
--form 'note_template_id=TEMP123' \\
--form 'note_guidelines_id=GUIDE456' \\
--form 'encounter_type_id=TYPE789' \\
--form 'audio=@file.mp3' \\
--form 'additional_data=[
  {
    "data_key": "chief_complaint",
    "data_value": "Patient complains of severe headache and dizziness",
    "data_type": "string",
    "fhir_resource_type": "Condition",
    "fhir_resource_id": "Condition/abc123"
  },
  {
    "data_key": "patient_name",
    "data_value": "John Doe",
    "data_type": "string"
  },
  {
    "data_key": "patient_age",
    "data_value": "45",
    "data_type": "integer",
    "fhir_resource_type": "Patient",
    "fhir_path": "Patient.age"
  },
  {
    "data_key": "patient_gender",
    "data_value": "male",
    "data_type": "string",
    "fhir_resource_type": "Patient",
    "fhir_path": "Patient.gender"
  },
  {
    "data_key": "medication_list",
    "data_value": "[\"Aspirin 81mg\", \"Lisinopril 10mg\"]",
    "data_type": "json",
    "fhir_resource_type": "MedicationStatement",
    "fhir_version": "4.0.1",
    "fhir_path": "MedicationStatement.medication"
  },
  {
    "data_key": "patient_mrn",
    "data_value": "MRN123456",
    "data_type": "string"
  },
  {
    "data_key": "encounter_date",
    "data_value": "2023-06-01",
    "data_type": "date",
    "fhir_resource_type": "Encounter",
    "fhir_path": "Encounter.period.start"
  },
  {
    "data_key": "provider_name",
    "data_value": "Dr. Jane Smith",
    "data_type": "string"
  }
]'`}
    </Code>
    <Text fontSize="md">
      Response Format (pretty-printed):
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "coding": {
    "api_call_durations": {
      "analyze_duration": 2.6700210571289062,
      "generate_note_duration": 0.2616300582885742,
      "transcribe_audio_duration": 10.819272994995117
    },
    "codes": {
      "job_id": 574,
      "note_id": 1716,
      "request_id": "1",
      "note": "The patient is a 55-year-old male presenting with a few weeks' history of neck and...",
      "structured_transcript": "**Patient:** I'm doing all right. I've been having this pain...",
      "transcribed_test": "okay I'm doing all right I've been having this pain in...",
      "results": {
        "cpt_em": {
          "code": "99204",
          "score": "moderate",
          "patienttype": "new",
          "em_score_contribution": {
            "analysis": "low",
            "problems": "moderate",
            "risk": "low"
          },
          "risk":
          {
            "high":
            {
                "likelihood score": "10",
                "reader discussion":
                {
                    "text": "there is no indication of drug therapy requiring..."
                }
            },
            "low":
            {},
            "moderate":
            {
                "likelihood score": "70",
                "reader discussion":
                {
                    "text": "the patient is being managed for suspected herniated cervical ..."
                }
            }
          },
          "problem type counts":
          {
              "4": 2
          },
          //additional data supporting the MDM determination
        },
        "icd10": {
          "M5412": {
            "diagnosis": {
              "texts": [
                {
                  "content": "the patient's symptoms and examination findings suggest a herniated ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "evidence": {
              "texts": [
                {
                  "content": "the patient is a 55-year-old male ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "match": {
              "explanation": "Based on the provided content, the ICD-10 code M54.12 (Radiculopathy, ...",
              "score": 100
            },
            "medication": {
              "texts": [
                {
                  "content": "prescribe a medrol dose pack for 7 days to manage inflammation.",
                  "end": "start",
                  "start": "end"
                }
              ]
            }
          }
        }
      }
    }
  }
}`}
    </Code>
    <Text fontSize="md">
      This endpoint returns a JSON object containing the CPT and ICD-10 codes extracted from the generated note, along with the durations of each API call involved in the process.
    </Text>




    <Heading as="h4" size="sm" mt={4} mb={2}>How to Handle Multiple Audio Files or Multiple Transcripts</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounter-analysis</Code> endpoint also supports processing multiple audio files or multiple transcripts. 
      If you send multiple audio files or multiple transcripts, they will be concatenated in the order they are received in the request.
    </Text>
    <Text fontSize="md">
      To send multiple audio files, use the <Code>audio</Code> form parameter multiple times:
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer TOKENHERE' \\
--header 'Content-Type: multipart/form-data' \\
--form 'audio=@file1.mp3' \\
--form 'audio=@file2.mp3' \\
--form 'audio=@file3.mp3'`}
    </Code>
    <Text fontSize="md">
      To send multiple transcripts, use the <Code>transcript</Code> form parameter multiple times:
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer TOKENHERE' \\
--header 'Content-Type: multipart/form-data' \\
--form 'transcript=First part of the transcript.' \\
--form 'transcript=Second part of the transcript.' \\
--form 'transcript=Third part of the transcript.'`}
    </Code>
    <Text fontSize="md">
      The concatenated data will be used for processing and analysis.
    </Text>






    <Text fontSize="md">
      Error Responses:
    </Text>
    <Code p={2} bg="gray.100" children={`400 Bad Request - No source data was included in the request.`} />
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - Transcription, note generation, or analysis failed.`} />
  </Box>






  <Box id="beta-encounter-analysis-cognitive">
      <Heading as="h4" size="md" mb={2}>Cognitive Assessment</Heading>
      <Text fontSize="md">
        The <Code>/beta/encounter_analysis</Code> endpoint performs a cognitive assessment based on the provided encounter data and returns recommended CPT codes with justifications.
      </Text>
      <Text fontSize="md">
        HTTP Method: <Code>POST</Code>
      </Text>
      <Text fontSize="md">
        URL: <Code>https://api.revedy.io/beta/encounter_analysis</Code>
      </Text>
      <Text fontSize="md">
        Headers:
      </Text>
      <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
      <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
      <Text fontSize="md" mt={4}>Request Body Format:</Text>
      <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
  "transcript": "Encounter transcript...",
  "professional_involvement": true,
  "technician_involvement": false,
  "face_to_face": true,
  "face_to_face_time": 30,
  "administration_time": 45,
  "scoring_time": 15,
  "interpretation_time": 20,
  "evaluation_time": 25,
  "comprehensive_cognitive_assessment": true,
  "qualified_provider": true,
}`} />
      <Text fontSize="md" mt={4}>Response Description:</Text>
      <Text fontSize="md">
        The response includes a "cpt_codes" object containing recommended CPT codes. Each CPT code is represented by an object with the following properties:
      <ul fontSize="md==sm">
        <li>billable: Boolean indicating if the code is billable</li>
        <li>cpt_code: The CPT code</li>
        <li>justification: Detailed explanation for recommending this code</li>
        <li>long_description: Official description of the CPT code</li>
        <li>units: Number of units to bill</li>
      </ul>
      </Text>
      

      <Text fontSize="md" mt={4}>Example response:</Text>
      <Code p={2} bg="gray.100" whiteSpace="pre" whiteSpace="pre-wrap" children=
{`{
  "cpt_codes": {
    "96130": {
      "billable": true,
      "cpt_code": "96130",
      "justification": "Tests administered: Orientation assessment, Memory recall (immediate and delayed) Qualified professional performed psychological testing evaluation services. Time spent: 120 minutes.",",
      "units": 1
    },
    "96131": {
      "billable": true,
      "cpt_code": "96131",
      "justification": "Tests administered: Orientation assessment, Memory recall (immediate and delayed). Qualified professional performed additional psychological testing evaluation services. Additional time spent: 60 minutes.",
      "long_description": "Psychological testing evaluation services by physician or other qualified health care professional, including integration of patient data, interpretation of standardized test results and clinical data, clinical decision making, treatment planning and report, and interactive feedback to the patient, family member(s) or caregiver(s), when performed; each additional hour (List separately in addition to code for primary procedure)",
      "units": 1
    },
    "96146": {
      "billable": true,
      "cpt_code": "96146",
      "justification": "Tests administered: Orientation assessment, Memory recall (immediate and delayed),. Automated test administered with automated results; testing is medically necessary and expected to impact care.",
      "long_description": "Psychological or neuropsychological test administration, with single automated, standardized instrument via electronic platform, with automated result only",
      "units": 1
    }
  }
}`} />
      <Text fontSize="md" mt={4}>
        Error Responses:
      </Text>
      <Code p={2} bg="gray.100" children={`400 Bad Request - No data provided or missing required fields.`} />
      <Code p={2} bg="gray.100" children={`500 Internal Server Error - An unexpected error occurred.`} />
      
      <Text fontSize="md" mt={4}>Sample CURL Request:</Text>
      <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://api.revedy.io/beta/encounter_analysis' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
--header 'Content-Type: application/json' \\
--data '{
  "transcript": "Patient exhibited signs of memory loss...",
  "reason_for_testing": "Suspected cognitive decline",
  "professional_involvement": true,
  "face_to_face": true,
  "face_to_face_time": 30,
  "administration_time": 45,
  "comprehensive_cognitive_assessment": true,
  "qualified_provider": true,
  "medical_necessity": true,
  "expected_to_impact_care": true
}'`} />
    </Box>
    









            <Divider />






            <Box id="encounter-manage">
              <Heading as="h3" size="lg" mb={2}>Encounter Manage</Heading>
              <Box id="encounters">
  <Heading as="h3" size="md" mb={2}>Encounters</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters</Code> endpoint retrieves a list of all encounters. Each encounter includes an external encounter ID, a unique identifier, and the creation date in ISO format.
  </Text>
  <Text fontSize="md" mt={4}>
    Example request to retrieve encounters:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request GET \\
--url 'https://api.revedy.io/v1/encounters' \\
--header 'Authorization: Bearer TOKEN'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
  "encounters": [
    {"external_encounter_id": "ENC123", "id": "b1a4ce7f-a9e9-4f13-8df0-66a221d57b57", "create_date": "2023-01-01T12:00:00Z"},
    {"external_encounter_id": "enc2", "id": "775ab4e4-f611-4a81-b077-7658f066c793", "create_date": "2023-01-02T12:00:00Z"},
    {"external_encounter_id": "enc2a", "id": "5f7c1c23-7dd3-4d35-b290-58cbedd08f13", "create_date": "2023-01-03T12:00:00Z"},
    {"external_encounter_id": "enc2b", "id": "04f13d77-f480-438d-a04d-52807da4dcf5", "create_date": "2023-01-04T12:00:00Z"},
    {"external_encounter_id": "enc2d", "id": "81792074-9e88-4db5-aa28-a4eff0e1ad3e", "create_date": "2023-01-05T12:00:00Z"}
  ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>

<Box id="encounters_search">
  <Heading as="h3" size="md" mb={2}>Search Encounters</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters/search</Code> endpoint allows for searching encounters based on external provider and patient IDs. This endpoint uses a POST request to ensure sensitive information is not exposed in the URL.
  </Text>

  <Link
    fontSize="md"
    fontWeight="normal"
    href="/el"
    target="_blank"
    rel="noopener noreferrer"
  >
    Try it out
  </Link>

  <Text fontSize="md" mt={4}>
    Example request to search encounters:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://api.revedy.io/v1/encounters/search' \\
--header 'Authorization: Bearer TOKEN' \\
--header 'Content-Type: application/json' \\
--data '{
  "external_provider_id": "PROVIDER123",
  "external_patient_id": "PATIENT456"
}'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
  "encounters": [
    {"external_encounter_id": "ENC123", "id": "b1a4ce7f-a9e9-4f13-8df0-66a221d57b57", "create_date": "2023-01-01T12:00:00Z"},
    {"external_encounter_id": "enc2", "id": "775ab4e4-f611-4a81-b077-7658f066c793", "create_date": "2023-01-02T12:00:00Z"}
  ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>

<Box id="specific-encounter">
  <Heading as="h3" size="md" mb={2}>Specific Encounter Details</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters/encounter_id</Code> endpoint retrieves detailed information about a specific encounter using its unique identifier.
    <br/>Notes that were coded by the system will be returned with the coding results.
    <br/>Updated notes provided by the user will not include the coding results.
    Coding results for the encounter can be requested via the <Code>/v1/encounters/encounter_id/codes endpoint</Code>.
  </Text>
  <Text fontSize="md" mt={4}>
    Example request to retrieve a specific encounter:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request GET \\
--url 'https://api.revedy.io/v1/encounters/{encounter_id}' \\
--header 'Authorization: Bearer TOKEN'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
    "external_encounter_id": "ZZZ01",
    "external_patient_id": "PAT456",
    "external_provider_id": "DOC789",
    "fhir_location_reference": "Location/789",
    "fhir_patient_reference": "Patient/123",
    "fhir_practitioner_reference": "Practitioner/456",
    "additional_data": [
        {
            "data_key": "medication_list",
            "data_type": "json",
            "data_value": "[\\"Aspirin 81mg\\", \\"Lisinopril 10mg\\"]",
            "fhir_display": null,
            "fhir_extension_url": null,
            "fhir_extension_value": null,
            "fhir_path": "MedicationStatement.medication",
            "fhir_resource_id": null,
            "fhir_resource_type": "MedicationStatement",
            "fhir_version": "4.0.1"
        },
        {
            "data_key": "allergy_intolerance",
            "data_type": "string",
            "data_value": "Penicillin",
            "fhir_display": "Allergy to Penicillin",
            "fhir_extension_url": "http://example.org/fhir/StructureDefinition/severity",
            "fhir_extension_value": "moderate",
            "fhir_path": "AllergyIntolerance.code.text",
            "fhir_resource_id": "AllergyIntolerance/xyz789",
            "fhir_resource_type": "AllergyIntolerance",
            "fhir_version": null
        }
    ],
    "notes": [
        {
          "coding": {},
          "note": "text of updated note",
          "note_created_at": "2024-05-31 19:44:31.103690",
          "note_id": 2830
        },
        {
          "note": "text of coded note",
          "note_created_at": "2024-05-31 18:42:08.272070",
          "note_id": 2828,
          "coding": {
                // Matches encounter-analysis
            }
        }
    ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`404 Not Found - No encounter found with the specified ID.`} />
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>



            </Box>
            <Divider />

            <Box id="notes-manage">
              <Heading as="h3" size="lg" mb={2}>Notes Manage</Heading>
              <Box id="notes-manage">
  <Heading as="h3" size="lg" mb={2}>Notes Manage</Heading>

  <Box id="save-note">
    <Heading as="h4" size="md" mb={2}>Save Note</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/notes</Code> endpoint allows you to save a new note for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/notes</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
    <Text fontSize="md" mt={4}>Request Body Format:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "note_text": "Note text...",
  "description": "Note description",
}`}
    </Code>
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{ "message": "Note saved successfully" }`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`400 Bad Request - Missing required note text or description.`} /><br/>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found or access denied.`} /><br/>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - Failed to save note.`} />
  </Box>

  <Box id="get-recent-note">
    <Heading as="h4" size="md" mb={2}>Get Recent Note</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/notes/recent</Code> endpoint retrieves the most recent note for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/notes/recent</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "recent_note": {
    "id": 2829,
    "note": "Note text...",
    "note_created_at": "Fri, 31 May 2024 18:43:01 GMT",
    "note_id": 2017,
    "transcription_raw_text": "transcription.",
    "transcription_structured_text": "\\nDoctor: Okay.",
    "version_id": 1
  }
}`}
    </Code>
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found, access denied, or no notes found for the encounter.`} /><br/>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - Failed to retrieve note.`} />
  </Box>

  <Box id="get-all-notes">
    <Heading as="h4" size="md" mb={2}>Get All Notes</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/notes/all</Code> endpoint retrieves all notes (up to 50) for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/notes/all</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "notes": [
    {
      "id": 2829,
      ...
    },
    {
      ...
    }
  ]
}`}
    </Code>
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found, access denied, or no notes found for the encounter.`} /><br/>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - Failed to retrieve notes.`} />
  </Box>
</Box>
              


            </Box>
            <Divider />

            <Box id="codes-manage">
  <Heading as="h3" size="lg" mb={2}>Codes Manage</Heading>
  {/* Add endpoints related to Codes Manage */}
  <Box id="current-encounter-codes">
    <Heading as="h4" size="md" mb={2}>Get Current Encounter Codes</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/current</Code> endpoint retrieves the current codes for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/current</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "current_codes": [
    {
      "code": "M50120",
      "confidence_score": 70,
      "create_date": "Fri, 31 May 2024 18:43:06 GMT",
      "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
      "source": "api",
      "status": "new",
      "status_notes": "The patient presents with symptoms indicative of a herniated cervical disc with cervical radiculopathy, specifically at the C5-6 or C6-7 levels. The symptoms include neck and left shoulder pain radiating to the first two fingers, weakness in the arm and hand, and a positive Sperling's maneuver, which supports the diagnosis of cervical radiculopathy. The ICD-10 code M50.120 refers to a \"Mid-cervical disc disorder, unspecified level,\" which is a general code for cervical disc disorders without specifying the exact level. Given the clinical findings and the specificity of the symptoms, a more precise ICD-10 code would be beneficial.",
      "type": "ICD-10"
    },
    {
      "code": "M25512",
      "confidence_score": 30,
      "create_date": "Fri, 31 May 2024 18:43:06 GMT",
      "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
      "source": "api",
      "status": "new",
      "status_notes": "The patient presents with neck and left shoulder pain radiating to the first two fingers, with associated weakness in the arm and hand. The physical examination and positive Sperling's maneuver suggest cervical radiculopathy, likely due to a herniated cervical disc at the C5-6 or C6-7 level. The differential diagnosis includes a rotator cuff issue, but the primary concern appears to be cervical radiculopathy. The ICD-10 code provided, M25.512, corresponds to \"Pain in left shoulder,\" which does not fully capture the primary diagnosis of cervical radiculopathy due to a herniated disc. The shoulder pain is a symptom, but the underlying cause is more accurately described by a code related to cervical radiculopathy.",
      "type": "ICD-10"
    }
  ]
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
  <Divider />

  <Box id="manage-encounter-code-status">
    <Heading as="h4" size="md" mb={2}>Manage Encounter Code Status</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/manage</Code> endpoint allows you to manage the status of a specific code for an encounter.
      The system will always return the most recent status for any code.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/manage</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
    <Text fontSize="md" mt={4}>Request Body Format:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "type": "CPT",
  "code": "CODE456",
  "status": "approved",
  "status_notes": "approved by Dr. Smith",
  "confidence_score": 100,
  "source": "provider"
}`}
    </Code>
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "message": "Encounter code status stored successfully"
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`400 Bad Request - Missing required fields.`} /><br/>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
  <Divider />

  <Box id="encounter-code-history">
    <Heading as="h4" size="md" mb={2}>Get Encounter Code History</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/history</Code> endpoint retrieves the history of codes for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/history</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "history": [
    {
      "code": "M25512",
      "confidence_score": 30,
      "create_date": "Fri, 31 May 2024 18:42:14 GMT",
      "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
      "source": "api",
      "status": "new",
      "status_note...
    }
  ]
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
</Box>













            <Divider />

            <Box id="patient-content-manage">
  <Heading as="h3" size="lg" mb={2}>Patient Content Manage</Heading>
  {/* Add endpoints related to Patient Content Manage */}
  <Box id="generate-patient-facing-content">
    <Heading as="h4" size="md" mb={2}>Generate Patient Facing Content</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/patient-facing-content</Code> endpoint generates patient-facing content for a specific encounter.
      <br/>If you provide user-provided content, this will be saved as the patient facing content.<br/>
      If you do not provide user-provided content, the system will generate patient-facing content based on the encounter data.<br/>
      The valid reading levels are "2nd grade," "5th grade," "10th grade".
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/patient-facing-content</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
    <Text fontSize="md" mt={4}>Request Body Format:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "reading_level": "5th grade",
  "user_provided_content": "User provided content (optional)"
}`}
    </Code>
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "patient_facing_content": "Generated patient-facing content"
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found or access denied.`} />
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
  <Divider />

  <Box id="get-recent-patient-facing-content">
    <Heading as="h4" size="md" mb={2}>Get Recent Patient Facing Content</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/patient-facing-content/recent</Code> endpoint retrieves the most recent patient-facing content for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/patient-facing-content/recent</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "recent_content": {
    "content": "Recent patient-facing content",
    "create_date": "Fri, 31 May 2024 18:58:54 GMT",
    "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
    "id": 10,
    "reading_level": "2nd grade",
    "status": "generated"
  }
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found or access denied, or no patient-facing content found.`} />
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
  <Divider />

  <Box id="get-all-patient-facing-content">
    <Heading as="h4" size="md" mb={2}>Get All Patient Facing Content</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounters/&lt;encounter_id&gt;/patient-facing-content/all</Code> endpoint retrieves all patient-facing content for a specific encounter.
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/patient-facing-content/all</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{
  "all_content": [
    {
      "content": "Patient-facing content 1",
      "create_date": "Fri, 31 May 2024 18:58:54 GMT",
      "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
      "id": 10,
      "reading_level": "2nd grade",
      "status": "generated"
    },
    {
      "content": "Patient-facing content 2",
      "create_date": "Fri, 31 May 2024 18:59:54 GMT",
      "encounter_id": "d1f64855-5c34-4a4a-b676-20e64dec9c38",
      "id": 11,
      "reading_level": "5th grade",
      "status": "user_provided"
    }
  ]
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`404 Not Found - Encounter not found or access denied, or no patient-facing content found.`} />
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
  </Box>
</Box>
            <Divider />










































                <Box id="get-recent-notes">
                    <Heading as="h3" size="md" mb={2}>Get Recent Notes</Heading>
                    <Text fontSize="md" >
                        The <Code>/getrecentnotes</Code> endpoint retrieves the most recent notes created by the user, up to a limit of 30 notes. Each note includes a snippet, version information, coding status, and processing time.
                    </Text>
                    <Text fontSize="md" >
                        HTTP Method: <Code>GET</Code>
                    </Text>
                    <Text fontSize="md" >
                        URL: <Code>https://api.revedy.io/getrecentnotes</Code>
                    </Text>
                    <Text fontSize="md" >
                        Headers:
                    </Text>
                    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                    <Text fontSize="md" >
                        Response Format (pretty-printed):
                    </Text>
                    <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
"notes": 
    [
        {
        "note_id": "unique_note_id",
        "note_snippet": "First 20 characters of the note...",
        "version_id": "version_identifier",
        "coding_status": "job_status",
        "id": "note_identifier",
        "process_time": "time_in_seconds"
        },
        // ... more notes
    ]
}`}
                    </Code>
                    <Text fontSize="md" >
                        This endpoint returns a JSON object containing an array of notes. If no notes are available, a sample note is created and returned in the response.
                    </Text>
                    <Text fontSize="md" >
                        Error Responses:
                    </Text>
                    <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
                    {/* Add more error responses as needed */}
                    </Box>
                    <Divider />

















        







<Box id="current-encounter-codes">
    <Heading as="h3" size="md" mb={2}>Get Current Encounter Codes</Heading>
    <Text fontSize="md">
        The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/current</Code> endpoint retrieves the current codes for a specific encounter.
    </Text>
    <Text fontSize="md">
        HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
        URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/current</Code>
    </Text>
    <Text fontSize="md">
        Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{ "current_codes": 
    [ 
      { "code": "CODE123", "type": "ICD-10", "status": "active" }, 
      { "code": "CODE456", "type": "CPT", "status": "inactive" } 
    ] 
  }`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
</Box>

<Divider />

<Box id="manage-encounter-code-status">
    <Heading as="h3" size="md" mb={2}>Manage Encounter Code Status</Heading>
    <Text fontSize="md">
        The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/manage</Code> endpoint allows you to manage the status of a specific code for an encounter.
        The system will always return the most recent status for any code.
    </Text>
    <Text fontSize="md">
        HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
        URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/manage</Code>
    </Text>
    <Text fontSize="md">
        Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
    <Text fontSize="md" mt={4}>Request Body Format:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
        {`{
    "type": "CPT",
    "code": "CODE456",
    "status": "approved",
    "status_notes": "approved by Dr. Smith",
    "confidence_score": 100,
    "source": "provider"
}`}
    </Code>
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{ "message": "Encounter code status stored successfully" }`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`400 Bad Request - Missing required fields.`} /><br/>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
</Box>

<Divider />

<Box id="encounter-code-history">
    <Heading as="h3" size="md" mb={2}>Get Encounter Code History</Heading>
    <Text fontSize="md">
        The <Code>/v1/encounters/&lt;encounter_id&gt;/codes/history</Code> endpoint retrieves the history of codes for a specific encounter.
    </Text>
    <Text fontSize="md">
        HTTP Method: <Code>GET</Code>
    </Text>
    <Text fontSize="md">
        URL: <Code>https://api.revedy.io/v1/encounters/&lt;encounter_id&gt;/codes/history</Code>
    </Text>
    <Text fontSize="md">
        Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Text fontSize="md" mt={4}>Example response:</Text>
    <Code p={2} bg="gray.100" whiteSpace="pre" children={`{ "history": 
    [ 
      { "code": "CODE123", "type": "ICD-10", "status": "active", "timestamp": "2023-01-01T12:00:00Z" }, 
      { "code": "CODE456", "type": "CPT", "status": "inactive", "timestamp": "2023-01-02T12:00:00Z" },
      { "code": "CODE456", "type": "CPT", "status": "active", "timestamp": "2023-01-03T12:00:00Z" },
      { "code": "CODE456", "type": "CPT", "status": "approved", "timestamp": "2023-01-04T12:00:00Z" } 
    ] 
}`} />
    <Text fontSize="md" mt={4}>Error Responses:</Text>
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
</Box>










                    <Box id="generate-note-from-data">
                    <Heading as="h3" size="md" mb={2}>Generate Note from Data</Heading>
                        <Text fontSize="md" >
                            The <Code>/generate_note_from_data</Code> endpoint is designed to process various types of input data and generate a medical note. It supports multiple data types, including transcripts, clinician notes, chat history, additional context, and patient records. The endpoint also integrates with a workflow system that orchestrates calls to various services for code generation and validation.
                        </Text>
                        <Text fontSize="md" >
                            HTTP Method: <Code>POST</Code>
                        </Text>
                        <Text fontSize="md" >
                            URL: <Code>https://api.revedy.io/generate_note_from_data</Code>
                        </Text>
                        <Text fontSize="md" >
                            Headers:
                        </Text>
                        <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                        <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
                        <Text fontSize="md" >
                            Request Body Format:
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "sent_data": "data_to_process",
    "external_sent_data_id": "optional_external_id",
    "transcript": "optional_transcript_data",
    "clinician notes": "optional_clinician_notes",
    "chat_history": "optional_chat_history",
    "additional_context": "optional_additional_context",
    "patient_record": "optional_patient_record"
}`}
                        </Code>
                        <Text fontSize="md" >
                            Response Format (pretty-printed):
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "generate_note_with_icd10_cpt_simple": {
        "data": "generated_note_data",
        "additional_info": "any_additional_information"
    },
// ... other workflow task results
}`}
                        </Code>
                        <Text fontSize="md" >
                            This endpoint returns a JSON object containing the results of the note generation workflow. The response includes the generated note along with any additional information from the workflow tasks.
                        </Text>
                        <Text fontSize="md" >
                            Error Responses:
                        </Text>
                        <Code p={2} bg="gray.100" children={`400 Bad Request - Client did not send sufficient data.`} />
                        <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                        <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred while running the workflow.`} />

</Box>

                
{/* Error Codes Section */}
<Box id="error-codes">
<Heading as="h2" size="lg" mb={2}>Error Codes</Heading>
<Text fontSize="md">
    The API uses the following error codes:
</Text>
<Container p={2} bg="gray.50" borderRadius="md">
    <Text fontSize="sm"><Code children="no_file_part" /> No file was included in the request.</Text>
    <Text fontSize="sm"><Code children="error generating event id" /> There was an error generating the event ID.</Text>
    <Text fontSize="sm"><Code children="transcription_failed" /> The transcription of the audio file failed.</Text>
    {/* Add more error codes as needed */}
</Container>
</Box>
                
                <Divider />

                {/* Rate Limits Section */}
                <Box id="rate-limits">
                <Heading as="h2" size="lg" mb={2}>Rate Limits</Heading>
                <Text fontSize="md">
                    The API limits the number of requests that can be made to prevent abuse. If you exceed these limits, you will receive a <Code children="429" /> Too Many Requests response.
                </Text>
                </Box>
                {/* Add more sections as needed */}



                <Box id="ionm-coding">
  <Heading as="h2" size="lg" mb={2}>IONM Coding</Heading>
  <Text fontSize="md">
    The IONM Coding endpoints allow you to manage and process Intraoperative Neurophysiological Monitoring (IONM) cases.
    <br />
    <br />
    To process a complete case in one call, use the <code>process_complete_ionm_case</code> call. This call will create a case, upload files, and process the case in one step.
    <br />

    <br />
    Or, to process a case step by step:
    <br />
    1) Create an external case,
    <br />2) Using the returned Case ID, upload files for the case. For each case, four filea are required:
    <br />- file type 1: Cadwell JSON file (required). This will include trace data and optionally chat history
    <br />- file type 2: Chat transcript (optional). Additional Chat History if available. May be either a pdf or docx file.
    <br />- file type 3: Technical Report. This is a PDF file that includes the technical report for the case. May be either a pdf or docx file.
    <br />- file type 4: System report. This is expected to have the output and screenshots from Cadwell. May be either a pdf or docx file.
    <br />3) Using the Case ID, process the case. Data will be returned with the coding results.
    <br /><br />Try these steps out in the Revedy UI:
    <br /> <Link target="_blank" href="https://app.revedy.io/ionmcase">https://app.revedy.io/ionmcase</Link>


    <br/><br/>All coded cases will be visible here:
    <br/>
    <Link target="_blank" href="https://app.revedy.io/ionmable">https://app.revedy.io/ionmable</Link>

  </Text>
</Box>



<Box id="process-complete-ionm-case">
  <Heading as="h3" size="md" mb={2}>Process Complete IONM Case</Heading>
  <Text fontSize="md">
    This endpoint processes a complete IONM case by creating a case, uploading and processing files, and then processing the entire case.
  </Text>
  <Text fontSize="md">
    HTTP Method: <Code>POST</Code>
  </Text>
  <Text fontSize="md">
    URL: <Code>https://api.revedy.io/process_complete_ionm_case</Code>
  </Text>
  <Text fontSize="md">
    Headers:
  </Text>
  <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: multipart/form-data`} />
  <Text fontSize="md" mt={4}>
    Request Body:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`source_case_id: string (required)
file_1: JSON file (required)
file_2: Chat file (optional)
file_3: Tech file (required)
file_4: Summary file (required)`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Request:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`curl -X POST \\
  'https://api.revedy.io/process_complete_ionm_case' \\
  -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  -F 'source_case_id=EXAMPLE_SOURCE_CASE_ID' \\
  -F 'file_1=@/path/to/your/json_file.json' \\
  -F 'file_2=@/path/to/your/chat_file.txt' \\
  -F 'file_3=@/path/to/your/tech_file.txt' \\
  -F 'file_4=@/path/to/your/summary_file.txt'`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
  "case_id": 12345,
  "external_case_id": "EXAMPLE_SOURCE_CASE_ID",
  "coding_results": {
    // Coding results object
  }
}`}
  </Code>
  <Text fontSize="md" mt={4}>
    Notes:
  </Text>
  <Text>
  <UnorderedList fontStyle={"normal"} fontSize="md" mt={4}>
    <ListItem>The <Code>source_case_id</Code> is required and should be a unique identifier for the case.</ListItem>
    <ListItem>File types are as follows:
      <UnorderedList>
        <ListItem><Code>file_1</Code>: JSON file (required)</ListItem>
        <ListItem><Code>file_2</Code>: Chat file (optional)</ListItem>
        <ListItem><Code>file_3</Code>: Tech file (required)</ListItem>
        <ListItem><Code>file_4</Code>: Summary file (required)</ListItem>
      </UnorderedList>
    </ListItem>
    <ListItem>All required files must be provided, or the request will return an error.</ListItem>
    <ListItem>Files should not be empty.</ListItem>
  </UnorderedList>
  </Text>
</Box>











<Box id="create-external-case">
  <Heading as="h3" size="md" mb={2}>Create External Case</Heading>
  <Text fontSize="md">
    This endpoint creates a new external IONM case.
  </Text>
  <Text fontSize="md">
    HTTP Method: <Code>POST</Code>
  </Text>
  <Text fontSize="md">
    URL: <Code>https://api.revedy.io/create_external_case</Code>
  </Text>
  <Text fontSize="md">
    Headers:
  </Text>
  <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`} />
  <Text fontSize="md" mt={4}>
    Request Body:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
  "external_case_id": "string" // required
}`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Request:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`curl -X POST \\
  https://api.revedy.io/create_external_case \\
  -H 'Authorization: Bearer YOUR_TOKEN_HERE' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "external_case_id": "CASE123"
}'`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
  "message": "External case created successfully",
  "case_id": 12345
}`}
  </Code>
</Box>

<Box id="upload-file">
  <Heading as="h3" size="md" mb={2}>Upload File</Heading>
  <Text fontSize="md">
    This endpoint uploads a file for a specific IONM case.
  </Text>
  <Text fontSize="md">
    HTTP Method: <Code>POST</Code>
  </Text>
  <Text fontSize="md">
    URL: <Code>https://api.revedy.io/upload_file/case_id</Code>
  </Text>
  <Text fontSize="md">
    Headers:
  </Text>
  <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: multipart/form-data`} />
  <Text fontSize="md" mt={4}>
    Request Body:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`file: File to be uploaded (required)
file_type_id: ID of the file type (required)`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Request:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`curl -X POST \\
  https://api.revedy.io/upload_file/1 \\
  -H 'Authorization: Bearer YOUR_TOKEN_HERE' \\
  -H 'Content-Type: multipart/form-data' \\
  -F 'file=@/path/to/your/file.txt' \\
  -F 'file_type_id=1'`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
  "message": "File uploaded successfully",
  "file_id": 67890
}`}
  </Code>
</Box>

<Box id="list-external-cases">
  <Heading as="h3" size="md" mb={2}>List External Cases</Heading>
  <Text fontSize="md">
    This endpoint retrieves a list of external IONM cases for the authenticated user.
  </Text>
  <Text fontSize="md">
    HTTP Method: <Code>GET</Code>
  </Text>
  <Text fontSize="md">
    URL: <Code>https://api.revedy.io/list_external_cases</Code>
  </Text>
  <Text fontSize="md">
    Headers:
  </Text>
  <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
  <Text fontSize="md" mt={4}>
    Sample Request:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`curl -X GET \\
  https://api.revedy.io/list_external_cases \\
  -H 'Authorization: Bearer YOUR_TOKEN_HERE'`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
  "cases": [
    {
      "id": 12345,
      "external_case_id": "CASE123",
      "files": {
        "1": {
          "file_name": "example.txt",
          "file_id": 67890
        }
      }
    }
  ]
}`}
  </Code>
</Box>

<Box id="process-ionm-case">
  <Heading as="h3" size="md" mb={2}>Process IONM Case</Heading>
  <Text fontSize="md">
    This endpoint processes an IONM case by integrating data and performing coding.
  </Text>
  <Text fontSize="md">
    HTTP Method: <Code>POST</Code>
  </Text>
  <Text fontSize="md">
    URL: <Code>https://api.revedy.io/process_ionm_case/case_id</Code>
  </Text>
  <Text fontSize="md">
    Headers:
  </Text>
  <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
  <Text fontSize="md" mt={4}>
    Sample Request:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`curl -X POST \\
  https://api.revedy.io/process_ionm_case/1 \\
  -H 'Authorization: Bearer YOUR_TOKEN_HERE'`}
  </Code>
  <Text fontSize="md" mt={4}>
    Sample Response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre">
{`

{
    "case_id": 1,
    "coding_results": {
        "CadwellCaseID": "ZZ742",
        "CaseDate": "2024-08-26T16:50:07.234752",
        "CodingResults": {
            "Modalities": [
                {
                    "child_modality": "Lower Extremity",
                    "parent_modality": "NEEDLE EMG"
                },
                {
                    "child_modality": "Axial Muscles",
                    "parent_modality": "NEEDLE EMG"
                },
                {
                    "child_modality": "Upper Extremity",
                    "parent_modality": "SSEP"
                },
                {
                    "child_modality": "Lower Extremity",
                    "parent_modality": "SSEP"
                },
                {
                    "child_modality": "Upper Extremity",
                    "parent_modality": "MEP"
                },
                {
                    "child_modality": "Lower Extremity",
                    "parent_modality": "MEP"
                },
                {
                    "child_modality": "EEG",
                    "parent_modality": "EEG_ECOG"
                },
                {
                    "child_modality": "(T04)",
                    "parent_modality": "TO4"
                }
            ],
            "pro": {
                "95861": {
                    "cpt_code": "95861-26",
                    "cpt_code_no_modifier": "95861",
                    "cpt_description": "EMG 2 Extremity, 5 muscles or more Needle electromyography, 2 extremities Needle electromyography; 2 extremities with or without related paraspinal areas. ",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "EMG 2 Extremity, 5 muscles or more. ",
                    "score": 79,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95861_baseline": {
                    "cpt_code": "95861-26",
                    "cpt_code_no_modifier": "95861",
                    "cpt_description": "EMG 2 Extremity, 5 muscles or more Needle electromyography, 2 extremities Needle electromyography; 2 extremities with or without related paraspinal areas. ",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "EMG 2 Extremity, 5 muscles or more.  Baseline.",
                    "score": 78.5,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95870_abdominals": {
                    "cpt_code": "95870-26",
                    "cpt_code_no_modifier": "95870",
                    "cpt_description": "EMG each extremity, 4 or less muscles OR abdominals ",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "EMG for abdominals. ",
                    "score": 76,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95870_abdominals_baseline": {
                    "cpt_code": "95870-26",
                    "cpt_code_no_modifier": "95870",
                    "cpt_description": "EMG each extremity, 4 or less muscles OR abdominals ",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "EMG for abdominals.  Baseline.",
                    "score": 75.5,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95938": {
                    "cpt_code": "95938-26",
                    "cpt_code_no_modifier": "95938",
                    "cpt_description": "SSEP Upper & Lower Limbs Short-latency somatosensory evoked potential study of upper and lower limbs Only code for this if there is SSEP Trials data",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "SSEP study with 2 upper limb modes and 2 lower limb modes",
                    "score": 96,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95939": {
                    "cpt_code": "95939-26",
                    "cpt_code_no_modifier": "95939",
                    "cpt_description": "MEP Upper & Lower Limbs Central motor evoked potential study of upper and lower limbs This code absolutely requires MEP, RMEP, or LMEP Trials data",
                    "issue_category": "",
                    "modifier": "26",
                    "reason": "MEP Upper & Lower Limbs.",
                    "score": 98,
                    "total_duration": "",
                    "type": "",
                    "units": 1
                },
                "95941-26": {
                    "cpt_code": "95941",
                    "cpt_code_no_modifier": "95941",
                    "cpt_description": "IONM remote 31-60 minutes Continuous intraoperative neurophysiological monitoring of multiple cases while in the operating room, per hour",
                    "issue_category": "",
                    "modifier": "",
                    "reason": "Remote monitoring time units to bill for: 1. Baseline Interpretation and Final Interpretation found. Messages: Baseline Interpretation: [Apr 30 2024 11:18:16 AM CST] ZZDrLast, ZZDrFirst :Baseline Interpretation: SSEPs monitorable and repeatable with less than 5% inter-run variability and signal-to-noise ratio > 2:1. EMG is quiet, EEG is symmetric. MEPs are present in the hands and feet with adequate signal-to-noise ratio and multiphasic morphology., Final Interpretation: [Apr 30 2024 12:20:37 PM CST] ZZDrLast, ZZDrFirst :Final Interpretation: Agree with closing statement, thanks. Total duration based on 2024-04-30 11:18:16 to 2024-04-30 12:20:37.",
                    "score": 100,
                    "total_duration": "1:02:21",
                    "type": "",
                    "units": 1
                }
`}
  </Code>
</Box>












                <Divider />
                <Box id="version">
                    <Button onClick={handleGetVersionClick} isLoading={loadingVersion}>
                        Get Version
                    </Button>
                    {version && (
                        <>
                          <Text>Version: {version}</Text>
                        </>
                    )}
                </Box>
                <Divider />












            </VStack>
            </GridItem>



        {/* Footer */}
        <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={4}>
          {/* Footer content */}
        </GridItem>
      </Grid>
    </Box>


  );
}

export default APIDocs;