//AdminEncountersList.js
import React, { useState, useEffect, useContext } from 'react';
import { 
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Table, Thead, Tbody, Tr, Th, Td, 
  Button, Heading, Spinner, useToast
} from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function AdminEncountersList() {
  const [encounters, setEncounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchEncounters();
  }, [accessToken]);

  const fetchEncounters = async () => {
    try {
      const response = await callApi('/admin_encounters', accessToken);
      if (response.ok) {
        const data = await response.json();
        setEncounters(data);
      } else {
        throw new Error('Failed to fetch encounters');
      }
    } catch (error) {
      console.error('Error fetching encounters:', error);
      toast({
        title: 'Error fetching encounters',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Grid
          templateAreas={`"header"    
                          "main"
                          "footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100vh'
          gap='1'
          color='blackAlpha.800'
        >
          <GridItem area={'header'}>
            <HeaderMenus />
          </GridItem>

          <GridItem bg='gray.50' area={'main'} overflowY="auto">
            <Box p={4}>
              <Heading mb={4}>Admin Encounters List</Heading>
              {loading ? (
                <Flex justify="center" align="center" height="200px">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Encounter ID</Th>
                      <Th>Date</Th>
                      <Th>Patient ID</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                      <Th>Assign</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {encounters.map((encounter) => (
                      <Tr key={encounter.id}>
                        <Td>{encounter.external_encounter_id}</Td>
                        <Td>{new Date(encounter.encounter_date).toLocaleDateString()}</Td>
                        <Td>{encounter.external_patient_id}</Td>
                        <Td>{encounter.has_clean_content ? 'Cleaned' : 'Not Cleaned'}</Td>


                        <Td>
                            <Button
                                as={RouterLink}
                                to={`/adminreview/${encounter.id}`}
                                colorScheme="blue"
                                onClick={(e) => {
                                e.preventDefault();
                                window.open(`/adminreview/${encounter.id}`, '_blank', 'noopener,noreferrer');
                                }}
                            >
                                {encounter.has_clean_content ? 'Edit' : 'Clean'}
                            </Button>
                        </Td>

                        <Td>
                            <Button
                                as={RouterLink}
                                to={`/assignreview/${encounter.id}`}
                                colorScheme="blue"
                                onClick={(e) => {
                                e.preventDefault();
                                window.open(`/assignreview/${encounter.id}`, '_blank', 'noopener,noreferrer');
                                }}
                            >
                                {'Assign'}
                            </Button>
                        </Td>


                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <ChatToggle
          contextType={'revedy'}
          contextData={'revedy_admin_encounters_list'}
          contextPersona={'revedy_admin_encounters_list'}
          firstMessage={"Hi, what can I do on this page?"}
          useOptions={false}
          maintainContext={true}
          contextMode={'popup'}
        />
      </Box>
    </ChakraProvider>
  );
}

export default AdminEncountersList;
