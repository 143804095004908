import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Text,
  Divider,
  useToast,
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";

function ReviewDetails() {
  const [reviewData, setReviewData] = useState(null);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  const { encounterId } = useParams();

  useEffect(() => {
    fetchReviewDetails();
  }, [accessToken, encounterId]);

  const fetchReviewDetails = async () => {
    try {
      const response = await callApi(`/review_details/${encounterId}`, accessToken);
      if (response.ok) {
        setReviewData(await response.json());
      } else {
        throw new Error('Failed to fetch review details');
      }
    } catch (error) {
      console.error('Error fetching review details:', error);
      toast({
        title: 'Error fetching review details',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!reviewData) return <Text>Loading...</Text>;

  return (
    <ChakraProvider>
      <Box p={6}>
        <Heading mb={6}>Review Details for Encounter {encounterId}</Heading>
        <VStack spacing={6} align="stretch">
          <Card>
            <CardHeader>
              <Heading size="md">Transcript Review</Heading>
            </CardHeader>
            <CardBody>
              <Text>Score: {reviewData.transcript_score}</Text>
              <Text>Comment: {reviewData.transcript_comment}</Text>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Heading size="md">Note Review</Heading>
            </CardHeader>
            <CardBody>
              <Text>Score: {reviewData.note_score}</Text>
              <Text>Comment: {reviewData.note_comment}</Text>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Heading size="md">Code Reviews</Heading>
            </CardHeader>
            <CardBody>
              {reviewData.code_reviews.map((codeReview, index) => (
                <Box key={index} mb={4}>
                  <Text>Code: {codeReview.code}</Text>
                  <Text>Score: {codeReview.score}</Text>
                  <Text>Comment: {codeReview.comment}</Text>
                  <Divider my={2} />
                </Box>
              ))}
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Heading size="md">Original Content</Heading>
            </CardHeader>
            <CardBody>
              <Text>Transcript: {reviewData.original_transcript}</Text>
              <Divider my={4} />
              <Text>Note: {reviewData.original_note}</Text>
            </CardBody>
          </Card>
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default ReviewDetails;