import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Text, Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import CptEmCodeDisplay from './CptEmCodeDisplay';

export const getNoteApi = async (accessToken, noteId) => {
  try {
    const response = await callApi('/getnote', accessToken, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ note_id: noteId }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error_code === 'no_note_id' || data.error_code === 'no_note_found') {
      throw new Error(data.error_code);
    }

    return data;
  } catch (error) {
    console.error('Error fetching note:', error);
    throw error;
  }
};

function AnalysisResults({ analysisResults }) {
  return (
    <Box mt={4}>
      <Text fontSize="xl" mb={2}>Analysis Results</Text>
      <CptEmCodeDisplay data={analysisResults.coding.codes.results.cpt_em} />
      <Text whiteSpace="pre-wrap">{JSON.stringify(analysisResults, null, 2)}</Text>
    </Box>
  );
}

function ExistingNoteAnalysis() {
  const [noteContent, setNoteContent] = useState('');
  const [isLoadingNote, setIsLoadingNote] = useState(true);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const { accessToken } = useContext(AppContext);
  const { noteId } = useParams();

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const data = await getNoteApi(accessToken, noteId);
        setNoteContent(data.note);
      } catch (error) {
        console.error('Error fetching note:', error);
      } finally {
        setIsLoadingNote(false);
      }
    };
    fetchNote();
  }, [noteId, accessToken]);

  const handleReanalyze = async () => {
    setIsAnalyzing(true);
    setAnalysisResults(null);

    try {
      const response = await callApi('/v1/reanalyze-note', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ note_id: noteId }),
      });

      if (response.ok) {
        const data = await response.json();
        setAnalysisResults(data);
      } else {
        console.error('Error in reanalysis');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <Box>
      <HeaderMenus />
      <Box m={4}>
        <Text fontSize="xl" mb={2}>Note Content:</Text>
        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          {isLoadingNote ? <Spinner /> : noteContent}
        </Box>
        <Button onClick={handleReanalyze} isLoading={isAnalyzing}>
          Re-Analyze
        </Button>
        {isAnalyzing && (
          <Box mt={2}>
            <Spinner />
            <Text>Analyzing...</Text>
          </Box>
        )}
        {analysisResults && <AnalysisResults analysisResults={analysisResults} />}
      </Box>
    </Box>
  );
}

export { ExistingNoteAnalysis, AnalysisResults };
