import React, { useState, useContext, useEffect } from 'react';
import {
  useToast, Box, Text, Button, Grid, GridItem, Flex, Input, Select, VStack, HStack, Table, Thead, Tbody, Tr, Th, Td, Icon,
  Textarea, Collapse, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  FormControl, FormLabel, Switch, NumberInput, NumberInputField
} from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import { FiFile, FiUpload, FiPlay, FiChevronDown, FiChevronUp, FiClipboard } from 'react-icons/fi';

function ExternalCaseManager() {
  const [externalCaseId, setExternalCaseId] = useState('');
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [cases, setCases] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [expandedCells, setExpandedCells] = useState({});
  const [textContent, setTextContent] = useState({});
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const [isDataCollectionOpen, setIsDataCollectionOpen] = useState(false);
  const [currentCaseId, setCurrentCaseId] = useState(null);
  const [cognitiveAssessmentData, setCognitiveAssessmentData] = useState({
    reasonForTesting: '',
    professionalInvolvement: false,
    interpretationTime: 0,
    technicianInvolvement: false,
    technicianTime: 0,
    faceToFace: false,
    faceToFaceTime: 0,
    comprehensiveAssessment: false,
    qualifiedProvider: false,
    additionalTesting: false,
    additionalTests: '',
    additionalTestingTime: 0,
    administrationTime: 0,
    scoringTime: 0,
    evaluationTime: 0,
    medicalNecessity: false,
    expectedToImpactCare: false,
  });

  const caseTypes = [
    { id: 2, name: 'Cognitive Assessment' },
    { id: 3, name: 'Labs' },
    { id: 4, name: 'Surgery' },
    { id: 5, name: 'IONM General' },
  ];

  const fileTypes = [
    { id: 5, name: 'Case Content 1' },
    { id: 6, name: 'Case Content 2' },
    { id: 7, name: 'Case Content 3' },
    { id: 8, name: 'Case Content 4' },
    { id: 9, name: 'Case Content 5' },
    { id: 10, name: 'Case Content 6' },
  ];

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      const response = await callApi('/list_external_cases', accessToken);
      const data = await response.json();
      if (response.ok) {
        setCases(data.cases);
      } else {
        throw new Error(data.error || 'Failed to fetch cases');
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
      toast({
        title: 'Failed to fetch cases',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreateCase = async () => {
    if (!externalCaseId || !selectedCaseType) {
      toast({
        title: 'Missing information',
        description: "Please enter an External Case ID and select a Case Type.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {
      const response = await callApi('/create_external_case', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          external_case_id: externalCaseId,
          case_type: selectedCaseType
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case created successfully',
          description: `Case ID: ${data.case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchCases();
        setExternalCaseId('');
        setSelectedCaseType('');
      } else {
        throw new Error(data.error || 'Failed to create case');
      }
    } catch (error) {
      console.error('Error creating case:', error);
      toast({
        title: 'Case creation failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const toggleExpand = (caseId, fileTypeId) => {
    setExpandedCells(prev => ({
      ...prev,
      [`${caseId}-${fileTypeId}`]: !prev[`${caseId}-${fileTypeId}`]
    }));
  };

  const handleTextChange = (caseId, fileTypeId, text) => {
    setTextContent(prev => ({
      ...prev,
      [`${caseId}-${fileTypeId}`]: text
    }));
  };

  const handleContentSubmit = async (caseId, fileTypeId, isFile = false) => {
    setProcessing(true);
    try {
      const formData = new FormData();
      formData.append('file_type_id', fileTypeId);

      if (isFile) {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.onchange = async (e) => {
          const file = e.target.files[0];
          if (!file) return;
          formData.append('file', file);

          await submitContent(caseId, formData, `/upload_file/${caseId}`);
        };
        fileInput.click();
      } else {
        const text = textContent[`${caseId}-${fileTypeId}`] || '';
        const blob = new Blob([text], { type: 'text/plain' });
        formData.append('file', blob, 'content.txt');

        await submitContent(caseId, formData, `/upload_content/${caseId}`);
      }
    } catch (error) {
      console.error('Error submitting content:', error);
      toast({
        title: 'Content submission failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const submitContent = async (caseId, formData, endpoint) => {
    const response = await callApi(endpoint, accessToken, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      toast({
        title: 'Content uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCases();
    } else {
      throw new Error(data.error || 'Failed to upload content');
    }
  };

  const handleCodeCase = async (caseId, caseType) => {
    setProcessing(true);
    try {
      const response = await callApi(`/create_and_code_case`, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          case_id: caseId,
          case_type: caseType,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case coded successfully',
          description: `External Case ID: ${data.external_case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchCases();
      } else {
        throw new Error(data.error || 'Failed to code case');
      }
    } catch (error) {
      console.error('Error coding case:', error);
      toast({
        title: 'Case coding failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleProcessCase = async (caseId) => {
    setProcessing(true);
    try {
      const response = await callApi(`/process_general_case`, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          case_id: caseId,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case processed successfully',
          description: `External Case ID: ${data.external_case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchCases();
      } else {
        throw new Error(data.error || 'Failed to process case');
      }
    } catch (error) {
      console.error('Error processing case:', error);
      toast({
        title: 'Case processing failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const openDataCollection = (caseId) => {
    setCurrentCaseId(caseId);
    setIsDataCollectionOpen(true);
  };

  const closeDataCollection = () => {
    setIsDataCollectionOpen(false);
    setCurrentCaseId(null);
  };

  const handleDataChange = (field, value) => {
    setCognitiveAssessmentData(prev => ({ ...prev, [field]: value }));
  };

  const submitCognitiveAssessmentData = async () => {
    setProcessing(true);
    try {
      const jsonData = JSON.stringify(cognitiveAssessmentData);
      const blob = new Blob([jsonData], { type: 'application/json' });
      
      const formData = new FormData();
      formData.append('file', blob, 'cognitive_assessment_data.json');
      formData.append('file_type_id', '5'); // Assuming 5 is for 'Case Content 1'
  
      const response = await callApi(`/upload_file/${currentCaseId}`, accessToken, {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Cognitive assessment data submitted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchCases();
        closeDataCollection();
      } else {
        throw new Error(data.error || 'Failed to submit cognitive assessment data');
      }
    } catch (error) {
      console.error('Error submitting cognitive assessment data:', error);
      toast({
        title: 'Submission failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };
  
  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontSize="lg" fontWeight="bold">Create External Case</Text>
                <HStack>
                  <Input
                    placeholder="Enter External Case ID"
                    value={externalCaseId}
                    onChange={(e) => setExternalCaseId(e.target.value)}
                  />
                  <Select
                    placeholder="Select Case Type"
                    value={selectedCaseType}
                    onChange={(e) => setSelectedCaseType(e.target.value)}
                  >
                    {caseTypes.map(type => (
                      <option key={type.id} value={type.name}>{type.name}</option>
                    ))}
                  </Select>
                  <Button
                    onClick={handleCreateCase}
                    disabled={processing || !externalCaseId || !selectedCaseType}
                    colorScheme="blue"
                  >
                    Create Case
                  </Button>
                </HStack>
              </Box>

              <Box>
                <Text fontSize="lg" fontWeight="bold">External Cases</Text>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Case ID</Th>
                      <Th>Actions</Th>
                      <Th>Case Type</Th>
                      {fileTypes.map(type => (
                        <Th key={type.id}>{type.name}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {cases.map(case_ => (
                      <Tr key={case_.id}>
                        <Td>{case_.id}</Td>

                        <Td>

                          <Button
                            size="sm"
                            onClick={() => handleCodeCase(case_.id, case_.case_type)}
                            leftIcon={<Icon as={FiPlay} />}
                            colorScheme="green"
                            isDisabled={processing || Object.keys(case_.files || {}).length === 0}
                          >
                            Code Case
                          </Button>
                        
                          {case_.encounter_id && (
                            <>
                            <br/><br/>
                            <Button
                              size="sm"
                              onClick={() => {
                                window.open(`/medical-necessity-general?case_id=${case_.id}`, '_blank', 'noopener,noreferrer');
                              }}
                              colorScheme="blue"
                            >
                              Scoring by Carrier
                            </Button>
                            </>
                          )}
                        </Td>

                        <Td>{case_.case_type}</Td>



                        {fileTypes.map(type => (
                            <Td key={type.id}>
                                <VStack>
                                {case_.case_type === 'Cognitive Assessment' && type.id === 5 ? (
                                    // For Cognitive Assessment cases and Case Content 1
                                    <Button
                                    size="sm"
                                    onClick={() => openDataCollection(case_.id)}
                                    leftIcon={<Icon as={FiClipboard} />}
                                    colorScheme="teal"
                                    >
                                    Collect Data
                                    </Button>
                                ) : (
                                    // For other cases or other content types
                                    <>
                                    {case_.files && case_.files[type.id] ? (
                                        <Text>{case_.files[type.id].file_name}</Text>
                                    ) : (
                                        <Text>No content</Text>
                                    )}
                                    <Button
                                        size="sm"
                                        onClick={() => toggleExpand(case_.id, type.id)}
                                        leftIcon={<Icon as={expandedCells[`${case_.id}-${type.id}`] ? FiChevronUp : FiChevronDown} />}
                                    >
                                        {expandedCells[`${case_.id}-${type.id}`] ? 'Hide' : 'Show'} Options
                                    </Button>
                                    <Collapse in={expandedCells[`${case_.id}-${type.id}`]}>
                                        <VStack>
                                        <Textarea
                                            placeholder="Enter text content"
                                            value={textContent[`${case_.id}-${type.id}`] || ''}
                                            onChange={(e) => handleTextChange(case_.id, type.id, e.target.value)}
                                        />
                                        <HStack>
                                            <Button
                                            size="sm"
                                            onClick={() => handleContentSubmit(case_.id, type.id, false)}
                                            leftIcon={<Icon as={FiUpload} />}
                                            >
                                            Submit Text
                                            </Button>
                                            <Button
                                            size="sm"
                                            onClick={() => handleContentSubmit(case_.id, type.id, true)}
                                            leftIcon={<Icon as={FiFile} />}
                                            >
                                            Upload File
                                            </Button>
                                        </HStack>
                                        </VStack>
                                    </Collapse>
                                    </>
                                )}
                                </VStack>
                            </Td>
                            ))}







                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </VStack>
          </Box>
        </GridItem>
      </Grid>

      <Modal isOpen={isDataCollectionOpen} onClose={closeDataCollection} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Collect Cognitive Assessment Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Reason for Testing</FormLabel>
                <Input
                  value={cognitiveAssessmentData.reasonForTesting}
                  onChange={(e) => handleDataChange('reasonForTesting', e.target.value)}
                />
              </FormControl>
              
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="professional-involvement" mb="0">
                  Professional Involvement
                </FormLabel>
                <Switch
                  id="professional-involvement"
                  isChecked={cognitiveAssessmentData.professionalInvolvement}
                  onChange={(e) => handleDataChange('professionalInvolvement', e.target.checked)}
                />
              </FormControl>

              {cognitiveAssessmentData.professionalInvolvement && (
                <FormControl>
                  <FormLabel>Interpretation Time (minutes)</FormLabel>
                  <NumberInput
                    value={cognitiveAssessmentData.interpretationTime}
                    onChange={(valueString) => handleDataChange('interpretationTime', parseInt(valueString))}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="technician-involvement" mb="0">
                  Technician Involvement
                </FormLabel>
                <Switch
                  id="technician-involvement"
                  isChecked={cognitiveAssessmentData.technicianInvolvement}
                  onChange={(e) => handleDataChange('technicianInvolvement', e.target.checked)}
                />
              </FormControl>

              {cognitiveAssessmentData.technicianInvolvement && (
                <FormControl>
                  <FormLabel>Technician Time (minutes)</FormLabel>
                  <NumberInput
                    value={cognitiveAssessmentData.technicianTime}
                    onChange={(valueString) => handleDataChange('technicianTime', parseInt(valueString))}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="face-to-face" mb="0">
                  Face-to-Face Assessment
                </FormLabel>
                <Switch
                  id="face-to-face"
                  isChecked={cognitiveAssessmentData.faceToFace}
                  onChange={(e) => handleDataChange('faceToFace', e.target.checked)}
                />
              </FormControl>

              {cognitiveAssessmentData.faceToFace && (
                <FormControl>
                  <FormLabel>Face-to-Face Time (minutes)</FormLabel>
                  <NumberInput
                    value={cognitiveAssessmentData.faceToFaceTime}
                    onChange={(valueString) => handleDataChange('faceToFaceTime', parseInt(valueString))}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="comprehensive-assessment" mb="0">
                  Comprehensive Assessment
                </FormLabel>
                <Switch
                  id="comprehensive-assessment"
                  isChecked={cognitiveAssessmentData.comprehensiveAssessment}
                  onChange={(e) => handleDataChange('comprehensiveAssessment', e.target.checked)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="qualified-provider" mb="0">
                  Qualified Provider
                </FormLabel>
                <Switch
                  id="qualified-provider"
                  isChecked={cognitiveAssessmentData.qualifiedProvider}
                  onChange={(e) => handleDataChange('qualifiedProvider', e.target.checked)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="additional-testing" mb="0">
                  Additional Testing
                </FormLabel>
                <Switch
                  id="additional-testing"
                  isChecked={cognitiveAssessmentData.additionalTesting}
                  onChange={(e) => handleDataChange('additionalTesting', e.target.checked)}
                />
              </FormControl>

              {cognitiveAssessmentData.additionalTesting && (
                <>
                  <FormControl>
                    <FormLabel>Additional Tests</FormLabel>
                    <Input
                      value={cognitiveAssessmentData.additionalTests}
                      onChange={(e) => handleDataChange('additionalTests', e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Additional Testing Time (minutes)</FormLabel>
                    <NumberInput
                      value={cognitiveAssessmentData.additionalTestingTime}
                      onChange={(valueString) => handleDataChange('additionalTestingTime', parseInt(valueString))}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </FormControl>
                </>
              )}

              <FormControl>
                <FormLabel>Administration Time (minutes)</FormLabel>
                <NumberInput
                  value={cognitiveAssessmentData.administrationTime}
                  onChange={(valueString) => handleDataChange('administrationTime', parseInt(valueString))}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Scoring Time (minutes)</FormLabel>
                <NumberInput
                  value={cognitiveAssessmentData.scoringTime}
                  onChange={(valueString) => handleDataChange('scoringTime', parseInt(valueString))}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Evaluation Time (minutes)</FormLabel>
                <NumberInput
                  value={cognitiveAssessmentData.evaluationTime}
                  onChange={(valueString) => handleDataChange('evaluationTime', parseInt(valueString))}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="medical-necessity" mb="0">
                  Medical Necessity
                </FormLabel>
                <Switch
                  id="medical-necessity"
                  isChecked={cognitiveAssessmentData.medicalNecessity}
                  onChange={(e) => handleDataChange('medicalNecessity', e.target.checked)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="expected-to-impact-care" mb="0">
                  Expected to Impact Care
                </FormLabel>
                <Switch
                  id="expected-to-impact-care"
                  isChecked={cognitiveAssessmentData.expectedToImpactCare}
                  onChange={(e) => handleDataChange('expectedToImpactCare', e.target.checked)}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={submitCognitiveAssessmentData} isLoading={processing}>
              Submit
            </Button>
            <Button variant="ghost" onClick={closeDataCollection}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ExternalCaseManager;