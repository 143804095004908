import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import { useToast } from "@chakra-ui/react";
import CptEmCodeDisplay from './CptEmCodeDisplay';

import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Text,
  Select,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Flex,
  Divider,
} from "@chakra-ui/react";

import ReactMarkdown from 'react-markdown';

function ReviewerDashboard() {
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [review, setReview] = useState({
    transcriptScore: 1,
    transcriptComment: '',
    noteScore: 1,
    noteComment: '',
    codeReviews: [],
  });
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchAssignments();
  }, [accessToken]);

  const fetchAssignments = async () => {
    try {
      const response = await callApi('/reviewer_assignments', accessToken);
      if (response.ok) {
        setAssignments(await response.json());
      } else {
        throw new Error('Failed to fetch assignments');
      }
    } catch (error) {
      console.error('Error fetching assignments:', error);
      toast({
        title: 'Error fetching assignments',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAssignmentSelect = async (assignmentId) => {
    try {
      const response = await callApi(`/assignment_details/${assignmentId}`, accessToken);
      if (response.ok) {
        const data = await response.json();
        
        setSelectedAssignment(data);
        setReview({
          transcriptScore: 1,
          transcriptComment: '',
          noteScore: 1,
          noteComment: '',
          codeReviews: data.codes.map((code) => ({
            codeId: code.id,
            score: 1,
            comment: '',
          })),
        });
      } else {
        throw new Error('Failed to fetch assignment details');
      }
    } catch (error) {
      console.error('Error fetching assignment details:', error);
      toast({
        title: 'Error fetching assignment details',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleReviewChange = (field, value) => {
    setReview((prevReview) => ({ ...prevReview, [field]: value }));
  };

  const handleCodeReviewChange = (index, field, value) => {
    setReview((prevReview) => {
      const newCodeReviews = [...prevReview.codeReviews];
      newCodeReviews[index] = { ...newCodeReviews[index], [field]: value };
      return { ...prevReview, codeReviews: newCodeReviews };
    });
  };

  const handleSubmitReview = async () => {
    try {
      const response = await callApi('/submit_review', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          assignmentId: selectedAssignment.assignment_id,
          encounterId: selectedAssignment.encounter_id,
          ...review,
        }),
      });

      if (response.ok) {
        // Handle successful submission
        fetchAssignments();
        setSelectedAssignment(null);
        toast({
          title: 'Review Submitted',
          description: 'Your review has been successfully submitted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to submit review');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      toast({
        title: 'Submission Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const cardBgColor = useColorModeValue("white", "gray.600");

  return (
    <ChakraProvider>
      <Box p={6} bg={bgColor} minHeight="100vh">
        <Heading mb={6}>Reviewer Dashboard</Heading>

        {/* Assignment Selection */}
        <Select
          placeholder="Select an assignment"
          onChange={(e) => handleAssignmentSelect(e.target.value)}
          mb={6}
          bg={cardBgColor}
        >
          {assignments.map((assignment) => (
            <option key={assignment.id} value={assignment.id}>
              Encounter {assignment.encounter_id}
            </option>
          ))}
        </Select>

        {selectedAssignment && (
          <>
            {/* Transcript, Note, and Scoring Side by Side */}
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              {/* Transcript */}
              <Box flex={1} pr={{ lg: 4 }} mb={{ base: 4, lg: 0 }}>
                <Card bg={cardBgColor} height="100%">
                  <CardHeader>
                    <Heading size="md">Transcript</Heading>
                  </CardHeader>
                  <CardBody overflowY="auto" maxHeight="70vh">
                    <ReactMarkdown>{selectedAssignment.transcript}</ReactMarkdown>
                  </CardBody>
                </Card>
              </Box>

              {/* Note */}
              <Box flex={1} pr={{ lg: 4 }} mb={{ base: 4, lg: 0 }}>
                <Card bg={cardBgColor} height="100%">
                  <CardHeader>
                    <Heading size="md">Note</Heading>
                  </CardHeader>
                  <CardBody overflowY="auto" maxHeight="70vh">
                    <ReactMarkdown>{selectedAssignment.note}</ReactMarkdown>
                  </CardBody>
                </Card>
              </Box>

              {/* Scoring and Comments */}
              <Box width={{ base: '100%', lg: '300px' }}>
                <VStack spacing={4} align="stretch">
                  <Card bg={cardBgColor}>
                    <CardHeader>
                      <Heading size="sm">Transcript Review</Heading>
                    </CardHeader>
                    <CardBody>
                      <NumberInput
                        min={1}
                        max={5}
                        value={review.transcriptScore}
                        onChange={(value) => handleReviewChange('transcriptScore', value)}
                        mb={2}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Textarea
                        value={review.transcriptComment}
                        onChange={(e) =>
                          handleReviewChange('transcriptComment', e.target.value)
                        }
                        placeholder="Comments on transcript"
                      />
                    </CardBody>
                  </Card>

                  <Card bg={cardBgColor}>
                    <CardHeader>
                      <Heading size="sm">Note Review</Heading>
                    </CardHeader>
                    <CardBody>
                      <NumberInput
                        min={1}
                        max={5}
                        value={review.noteScore}
                        onChange={(value) => handleReviewChange('noteScore', value)}
                        mb={2}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Textarea
                        value={review.noteComment}
                        onChange={(e) =>
                          handleReviewChange('noteComment', e.target.value)
                        }
                        placeholder="Comments on note"
                      />
                    </CardBody>
                  </Card>
                </VStack>
              </Box>
            </Flex>

            {/* Codes Section */}
            <Box mt={6}>
              <Heading size="md" mb={4}>
                Codes
              </Heading>
              {selectedAssignment.codes.map((code, index) => (
                <Flex
                  key={code.id}
                  flexDirection={{ base: 'column', lg: 'row' }}
                  mb={4}
                >
                  {/* Code Details */}
                  <Box flex={1} pr={{ lg: 4 }} mb={{ base: 4, lg: 0 }}>
                    <Card bg={cardBgColor}>
                      <CardHeader>
                        <Text fontWeight="bold">
                          {code.code_type}: {code.code}
                        </Text>
                        <Text fontSize="sm">{code.description}</Text>
                        <Text>Confidence: {code.confidence_score}</Text>
                      </CardHeader>
                      <CardBody>
                      {code.code_type === 'CPT' && code.status_notes && (
                        (() => {
                          try {
                            const parsedData = JSON.parse(code.status_notes);
                            return <CptEmCodeDisplay data={parsedData} />;
                          } catch (error) {
                            // If parsing fails, it's not valid JSON, so just display the raw value
                            return <span>{code.status_notes}</span>;
                          }
                        })()
                      )}

                  
                      </CardBody>
                    </Card>
                  </Box>

                  {/* Code Review */}
                  <Box width={{ base: '100%', lg: '300px' }}>
                    <Card bg={cardBgColor}>
                      <CardHeader>
                        <Heading size="sm">Code Review</Heading>
                      </CardHeader>
                      <CardBody>
                        <NumberInput
                          min={1}
                          max={5}
                          value={review.codeReviews[index].score}
                          onChange={(value) =>
                            handleCodeReviewChange(index, 'score', value)
                          }
                          mb={2}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Textarea
                          value={review.codeReviews[index].comment}
                          onChange={(e) =>
                            handleCodeReviewChange(index, 'comment', e.target.value)
                          }
                          placeholder="Comments on code"
                        />
                      </CardBody>
                    </Card>
                  </Box>
                </Flex>
              ))}
              <Button
                onClick={handleSubmitReview}
                colorScheme="blue"
                mt={6}
                size="lg"
              >
                Submit Review
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default ReviewerDashboard;
