import React, { useState, useContext } from 'react';
import {
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Input, Button,
  Heading, Textarea, FormControl, FormLabel, FormHelperText, useToast,
  Switch, NumberInput, NumberInputField, SimpleGrid, Divider, Stack
} from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', // light blue
    800: '#219EBC', // blue
    700: '#023047', // dark blue
    600: '#FFB703', // yellow
    500: '#FB8500'  // orange
  },
};

const theme = extendTheme({ colors });

function CognitiveAssessment() {
  // State variables
  const [transcript, setTranscript] = useState('');
  const [reasonForTesting, setReasonForTesting] = useState('');
  const [icd10Codes, setIcd10Codes] = useState('');
  const [testsAdministered, setTestsAdministered] = useState('');

  // Since automated test is always done
  const automatedTest = true;

  // Professional Involvement
  const [professionalInvolvement, setProfessionalInvolvement] = useState(false);
  const [interpretationTime, setInterpretationTime] = useState(0);
  const [qualifiedProviderInvolved, setQualifiedProviderInvolved] = useState(false);
  const [evaluationTime, setEvaluationTime] = useState(0);

  // Technician Involvement
  const [technicianInvolvement, setTechnicianInvolvement] = useState(false);
  const [technicianTime, setTechnicianTime] = useState(0);

  // Face-to-Face Assessment
  const [faceToFace, setFaceToFace] = useState(false);
  const [faceToFaceTime, setFaceToFaceTime] = useState(0);
  const [comprehensiveAssessment, setComprehensiveAssessment] = useState(false);
  const [qualifiedProvider, setQualifiedProvider] = useState(false);

  // Additional Testing
  const [additionalTesting, setAdditionalTesting] = useState(false);
  const [additionalTests, setAdditionalTests] = useState('');
  const [additionalTestingTime, setAdditionalTestingTime] = useState(0);

  // Time Spent on Activities
  const [administrationTime, setAdministrationTime] = useState(0);
  const [scoringTime, setScoringTime] = useState(0);
  // interpretationTime and evaluationTime are already defined above

  // Medical Necessity and Impact on Care
  const [medicalNecessity, setMedicalNecessity] = useState(false);
  const [expectedToImpactCare, setExpectedToImpactCare] = useState(false);

  // Processing and Results
  const [processing, setProcessing] = useState(false);
  const [resultData, setResultData] = useState(null);

  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const handleSubmit = async () => {
    setProcessing(true);
    setResultData(null);

    try {
      const dataToSend = {
        transcript,
        reason_for_testing: reasonForTesting,
        professional_involvement: professionalInvolvement,
        technician_involvement: technicianInvolvement,
        face_to_face: faceToFace,
        face_to_face_time: parseInt(faceToFaceTime, 10),
        administration_time: parseInt(administrationTime, 10),
        scoring_time: parseInt(scoringTime, 10),
        interpretation_time: parseInt(interpretationTime, 10),
        evaluation_time: parseInt(evaluationTime, 10),
        comprehensive_cognitive_assessment: comprehensiveAssessment,
        qualified_provider: qualifiedProvider,
        automated_test: automatedTest,
        tests_administered: testsAdministered,
        additional_testing: additionalTesting,
        additional_tests: additionalTests,
        additional_testing_time: parseInt(additionalTestingTime, 10),
        icd10_codes: icd10Codes.split(',').map(code => code.trim()),
        medical_necessity: medicalNecessity,
        expected_to_impact_care: expectedToImpactCare,
      };

      const response = await callApi('/beta/encounter_analysis', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      const data = await response.json();
      if (response.ok) {
        setResultData(data);
        toast({
          title: 'Success',
          description: 'CPT codes have been retrieved.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Failed',
          description: data.error || 'An error occurred while processing data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error processing cognitive assessment:', error);
      toast({
        title: 'Processing failed',
        description: 'There was an error processing your request.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Grid
          templateAreas={`"header"
                          "main"
                          "footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100vh'
          gap='1'
          color='blackAlpha.800'
        >
          <GridItem area={'header'}>
            <HeaderMenus />
          </GridItem>

          <GridItem bg='gray.50' area={'main'} overflowY="auto">
            <Box p={4}>
              <VStack spacing={6} align="stretch">
                <Heading mb={4}>Cognitive Assessment CPT Coder</Heading>

                {/* 1. Patient and Case Information */}
                 

                <Divider />

                {/* 2. Transcript of Automated Test */}
                <Box>
                  <Heading size="md" mb={2}>Transcript of Automated Test</Heading>

                  {/* Transcript */}
                  <FormControl isRequired>
                    <FormLabel>Transcript</FormLabel>
                    <Textarea
                      placeholder="Enter the patient's transcript here"
                      value={transcript}
                      onChange={(e) => setTranscript(e.target.value)}
                      minHeight="120px"
                    />
                    <FormHelperText>
                      Provide the transcript of the patient's interaction or session. This should include any tests administered, patient responses, and observations.
                    </FormHelperText>
                  </FormControl>

                </Box>

                <Divider />

                {/* 3. Additional Activities */}
                <Box>
                  <Heading size="md" mb={2}>Additional Activities</Heading>

                  {/* Professional Involvement */}
                  <Heading size="sm" mt={4}>Professional Involvement</Heading>

                  <FormControl display="flex" alignItems="center" mt={2}>
                    <FormLabel htmlFor="professional-involvement" mb="0">
                      Test Interpreted by a Qualified Professional?
                    </FormLabel>
                    <Switch
                      id="professional-involvement"
                      isChecked={professionalInvolvement}
                      onChange={(e) => setProfessionalInvolvement(e.target.checked)}
                    />
                  </FormControl>

                  {professionalInvolvement && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel>Time Spent on Interpretation (minutes)</FormLabel>
                        <NumberInput min={0} value={interpretationTime} onChange={(valueString) => setInterpretationTime(valueString)}>
                          <NumberInputField />
                        </NumberInput>
                        <FormHelperText>
                          Time spent by the professional interpreting the results.
                        </FormHelperText>
                      </FormControl>

                      <FormControl display="flex" alignItems="center" mt={4}>
                        <FormLabel htmlFor="qualified-provider-involved" mb="0">
                          Qualified Provider Involved in Care Planning?
                        </FormLabel>
                        <Switch
                          id="qualified-provider-involved"
                          isChecked={qualifiedProviderInvolved}
                          onChange={(e) => setQualifiedProviderInvolved(e.target.checked)}
                        />
                      </FormControl>

                      {qualifiedProviderInvolved && (
                        <FormControl mt={4}>
                          <FormLabel>Time Spent on Evaluation/Care Planning (minutes)</FormLabel>
                          <NumberInput min={0} value={evaluationTime} onChange={(valueString) => setEvaluationTime(valueString)}>
                            <NumberInputField />
                          </NumberInput>
                          <FormHelperText>
                            Time spent on clinical decision-making and report preparation.
                          </FormHelperText>
                        </FormControl>
                      )}
                    </>
                  )}

                  {/* Technician Involvement */}
                  <Heading size="sm" mt={6}>Technician Involvement</Heading>

                  <FormControl display="flex" alignItems="center" mt={2}>
                    <FormLabel htmlFor="technician-involvement" mb="0">
                      Technician Assisted with Test?
                    </FormLabel>
                    <Switch
                      id="technician-involvement"
                      isChecked={technicianInvolvement}
                      onChange={(e) => setTechnicianInvolvement(e.target.checked)}
                    />
                  </FormControl>

                  {technicianInvolvement && (
                    <FormControl mt={4}>
                      <FormLabel>Time Spent by Technician (minutes)</FormLabel>
                      <NumberInput min={0} value={technicianTime} onChange={(valueString) => setTechnicianTime(valueString)}>
                        <NumberInputField />
                      </NumberInput>
                      <FormHelperText>
                        Time spent by technician on administration/scoring.
                      </FormHelperText>
                    </FormControl>
                  )}

                  {/* Face-to-Face Assessment */}
                  <Heading size="sm" mt={6}>Face-to-Face Assessment</Heading>

                  <FormControl display="flex" alignItems="center" mt={2}>
                    <FormLabel htmlFor="face-to-face" mb="0">
                      Additional Face-to-Face Assessment?
                    </FormLabel>
                    <Switch
                      id="face-to-face"
                      isChecked={faceToFace}
                      onChange={(e) => setFaceToFace(e.target.checked)}
                    />
                  </FormControl>

                  {faceToFace && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel>Face-to-Face Time (minutes)</FormLabel>
                        <NumberInput min={0} value={faceToFaceTime} onChange={(valueString) => setFaceToFaceTime(valueString)}>
                          <NumberInputField />
                        </NumberInput>
                        <FormHelperText>
                          Total time spent in face-to-face interaction with the patient.
                        </FormHelperText>
                      </FormControl>

                      <FormControl display="flex" alignItems="center" mt={4}>
                        <FormLabel htmlFor="comprehensive-assessment" mb="0">
                          Comprehensive Cognitive Assessment?
                        </FormLabel>
                        <Switch
                          id="comprehensive-assessment"
                          isChecked={comprehensiveAssessment}
                          onChange={(e) => setComprehensiveAssessment(e.target.checked)}
                        />
                      </FormControl>

                      <FormControl display="flex" alignItems="center" mt={4}>
                        <FormLabel htmlFor="qualified-provider" mb="0">
                          Qualified Provider Performed the Assessment?
                        </FormLabel>
                        <Switch
                          id="qualified-provider"
                          isChecked={qualifiedProvider}
                          onChange={(e) => setQualifiedProvider(e.target.checked)}
                        />
                      </FormControl>
                    </>
                  )}

                  {/* Additional Testing */}
                  <Heading size="sm" mt={6}>Additional Testing</Heading>

                  <FormControl display="flex" alignItems="center" mt={2}>
                    <FormLabel htmlFor="additional-testing" mb="0">
                      Additional Tests Administered?
                    </FormLabel>
                    <Switch
                      id="additional-testing"
                      isChecked={additionalTesting}
                      onChange={(e) => setAdditionalTesting(e.target.checked)}
                    />
                  </FormControl>

                  {additionalTesting && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel>Names of Additional Tests</FormLabel>
                        <Input
                          placeholder="Enter the names of the additional tests"
                          value={additionalTests}
                          onChange={(e) => setAdditionalTests(e.target.value)}
                        />
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>Time Spent on Additional Testing (minutes)</FormLabel>
                        <NumberInput min={0} value={additionalTestingTime} onChange={(valueString) => setAdditionalTestingTime(valueString)}>
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                    </>
                  )}

                </Box>

                <Divider />

                {/* 4. Time Spent on Activities */}
                <Box>
                  <Heading size="md" mb={2}>Time Spent on Activities</Heading>

                  <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
                    <FormControl>
                      <FormLabel>Administration Time (minutes)</FormLabel>
                      <NumberInput min={0} value={administrationTime} onChange={(valueString) => setAdministrationTime(valueString)}>
                        <NumberInputField />
                      </NumberInput>
                      <FormHelperText>
                        Time spent administering tests to the patient.
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Scoring Time (minutes)</FormLabel>
                      <NumberInput min={0} value={scoringTime} onChange={(valueString) => setScoringTime(valueString)}>
                        <NumberInputField />
                      </NumberInput>
                      <FormHelperText>
                        Time spent scoring the tests administered.
                      </FormHelperText>
                    </FormControl>

                    {/* Interpretation Time and Evaluation Time captured under Professional Involvement */}
                    {/* Additional Testing Time captured under Additional Testing */}
                  </SimpleGrid>
                </Box>

                <Divider />

                {/* 5. Medical Necessity and Impact on Care */}
                <Box>

                </Box>

                <Divider />

                {/* Submit Button */}
                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={processing}
                  loadingText="Processing"
                  width="full"
                >
                  Submit
                </Button>

                {/* Result */}
                {resultData && (
                  <Box mt={4}>
                    <Heading size="md" mb={2}>CPT Code Results</Heading>
                    <Box as="pre" p={4} bg="gray.100" borderRadius="md" overflowX="auto" whiteSpace="pre-wrap">
                      {JSON.stringify(resultData, null, 2)}
                    </Box>
                  </Box>
                )}
              </VStack>
            </Box>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <ChatToggle
          contextType={'revedy'}
          contextData={'revedy_cognitive_assessment'}
          contextPersona={'revedy_cognitive_assessment'}
          firstMessage={"Hi, what can I do on this page?"}
          useOptions={false}
          maintainContext={true}
          contextMode={'popup'}
        />
      </Box>
    </ChakraProvider>
  );
}

export default CognitiveAssessment;