import React, { useState, useContext } from 'react';
import { Box, Grid, GridItem, Input, Textarea, Button, Text, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import CptEmCodeDisplay from './CptEmCodeDisplay';
//import ICD10Display from './ICD10Display'; // Placeholder component

function NoteEdit() {
  const [externalNote, setExternalNote] = useState('');
  const [externalPatientId, setExternalPatientId] = useState('');
  const [encounterId, setEncounterId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);

  const { accessToken } = useContext(AppContext);
  const navigate = useNavigate();

  const handleDataSubmit = async () => {
    setIsLoading(true);
    setAnalysisResults(null);

    const formData = new FormData();
    formData.append('encounter_id', encounterId);
    formData.append('external_patient_id', externalPatientId);
    formData.append('external_note', externalNote);
    formData.append('analysis_type', 'coding');

    try {
      const response = await callApi('/v1/encounter-analysis', accessToken, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setAnalysisResults(data);
      } else {
        // Handle errors
        console.error('Error in API call');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setIsLoading(false);
  };

  return (
    <Box>
      <HeaderMenus />
      <Grid templateColumns="repeat(2, 1fr)" gap={4} m={4}>
        <GridItem colSpan={1}>
          <Input
            placeholder="External Patient ID"
            value={externalPatientId}
            onChange={(e) => setExternalPatientId(e.target.value)}
            mb={2}
          />
          <Input
            placeholder="Encounter ID"
            value={encounterId}
            onChange={(e) => setEncounterId(e.target.value)}
            mb={2}
          />
          <Textarea
            placeholder="Enter note here"
            value={externalNote}
            onChange={(e) => setExternalNote(e.target.value)}
            height="300px"
            mb={2}
          />
          <Button onClick={handleDataSubmit} isLoading={isLoading}>
            Analyze
          </Button>
        </GridItem>
        <GridItem colSpan={1}>
          {isLoading ? (
            <Spinner />
          ) : analysisResults ? (
            <Box>
              <Text fontSize="xl" mb={2}>Analysis Results</Text>
              <CptEmCodeDisplay data={analysisResults.coding.codes.results.cpt_em} />
              <Text fontSize="xl" mb={2}>Full Data in JSON</Text>
                <Text whiteSpace="pre-wrap">{JSON.stringify(analysisResults, null, 2)}</Text>
              
            </Box>
          ) : (
            <Text>Enter a note and click Analyze to see results.</Text>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
}

export default NoteEdit;